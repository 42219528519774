import React from "react";
import PropTypes from "prop-types";
import styles from "./NumberInput.module.css";

const NumberInput = React.forwardRef(
  ({ label, placeholder, error, ...props }, ref) => (
    <div className={styles.inputWrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <input
        type="tel" 
        ref={ref}
        placeholder={placeholder}
        className={`${styles.input} ${error ? styles.inputError : ""}`}
        {...props}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  )
);

NumberInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.object
  ]), 
};

export default NumberInput;
