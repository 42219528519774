import React from "react";
import TextInput from "../../../components/inputs/TextInput";
import FileInput from "../../../components/inputs/FileInput";
import SelectInput from "../../../components/inputs/SelectInput";
import styles from "./CompanyAccountForm.module.css";
import { useForm } from "react-hook-form";

const CompanyAccountForm = ({  data }) => {
  const companyInfo = data?.user_info;
  const {
    register,
    formState: { errors },
  } = useForm();
  return (
    <div className={styles.form}>
      <div className="grid--2 gap--xs grid--s-1">
        <TextInput
          label="Company Name"
          placeholder="Enter your company name"
          name="company_name"
          defaultValue={companyInfo?.company_name}
          register={register}
          error={errors?.company_name}
        />

        <TextInput
          label="Business Registration Number"
          placeholder="Enter your business registration number"
          name="business_registration_number"
          defaultValue={companyInfo?.business_registration_number}
          register={register}
          error={errors?.business_registration_number}
        />
      </div>

      <div className="grid--3 gap--xs grid--s-1">
        <TextInput
          label="Business Email"
          placeholder="Enter your business email"
          name="business_email"
          defaultValue={companyInfo?.business_email}
          register={register}
          error={errors?.business_email}
        />

        <TextInput
          label="Business Phone"
          placeholder="Enter your business phone number"
          name="business_number"
          defaultValue={companyInfo?.business_number}
          register={register}
          error={errors?.business_number}
        />

        <TextInput
          label="Website (URL)"
          placeholder="www.yourcompany.com"
          name="website"
          defaultValue={companyInfo?.website}
          register={register}
          error={errors?.website}
        />
      </div>

      <div className="grid--2 gap--xs grid--s-1">
        <SelectInput
          label="Country"
          name="country"
          defaultValue={companyInfo?.country}
          register={register}
          options={[
            { value: "Canada", label: "Canada" },
            { value: "USA", label: "United States of America" },
            { value: "UK", label: "United Kingdom" },
          ]}
          error={errors?.country}
        />

        <TextInput
          label="City"
          placeholder="Enter your city"
          name="city"
          defaultValue={companyInfo?.city}
          register={register}
          error={errors?.city}
        />
      </div>

      <TextInput
        label="Company Address"
        placeholder="Enter your company address"
        name="company_address"
        defaultValue={companyInfo?.company_address}
        register={register}
        error={errors?.company_address}
      />

      <div className="grid--2 grid--s-1 gap--xs">
        <FileInput
          label="Resume"
          name="resume"
          register={register}
          error={errors?.resume}
        />

        <FileInput
          label="Portfolio"
          name="portfolio"
          register={register}
          error={errors?.portfolio}
        />
      </div>
    </div>
  );
};

export default CompanyAccountForm;
