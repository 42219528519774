// src/components/AuthBackground.js
import React, { useEffect, useRef } from "react";
import styles from "./AuthBackground.module.css";
import { gsap } from "../../../plugins/gsap.min.js";
import { MorphSVGPlugin } from "../../../plugins/MorphSVGPlugin.min.js";

gsap.registerPlugin(MorphSVGPlugin);

const AuthBackground = ({ route }) => {
  const ball1Ref = useRef(null);
  const ball2Ref = useRef(null);
  const smallBall1Ref = useRef(null);
  const smallBall2Ref = useRef(null);

  useEffect(() => {
    // Route-based animations
    if (route === "/login") {
      gsap.to(ball1Ref.current, {
        x: -100,
        y: 800,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(ball2Ref.current, {
        x: 100,
        y: -800,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(smallBall2Ref.current, {
        x: 550,
        y: -500,
        opacity: 0,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(smallBall1Ref.current, {
        x: 500,
        y: 500,
        opacity: 0,
        duration: 1,
        ease: "power2.inOut",
      });
    } else if (route === "/register") {
      gsap.to(ball1Ref.current, {
        x: -100,
        y: -100,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(ball2Ref.current, {
        x: 100,
        y: 100,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(smallBall2Ref.current, {
        x: 500,
        y: 500,
        opacity: 0,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(smallBall1Ref.current, {
        x: 0,
        y: 0,
        opacity: 0,
        duration: 1,
        ease: "power2.inOut",
      });
    } else if (route === "/forgot-password") {
      gsap.to(ball1Ref.current, {
        x: -350,
        y: -350,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(ball2Ref.current, {
        x: 350,
        y: 350,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(smallBall2Ref.current, {
        x: 700,
        y: -500,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(smallBall1Ref.current, {
        x: 0,
        y: 800,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      });
    } else {
      gsap.to([ball1Ref.current, ball2Ref.current], {
        x: 0,
        y: 0,
        duration: 1,
        opacity: 1,
        ease: "power2.inOut",
      });
    
    }
  }, [route]);

  return (
    <div className={styles["background"]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1512 982"
        fill="none"
        className={styles["svg-background"]}
      >
        <path
          ref={ball1Ref}
          d="M324.5 523C666.64 523 944 301.157 944 27.5C944 -246.157 666.64 -468 324.5 -468C-17.6404 -468 -295 -246.157 -295 27.5C-295 301.157 -17.6404 523 324.5 523Z"
          fill="#F9BF85"
          className={styles["ball1"]}
        />
        <path
          ref={ball2Ref}
          d="M1170.5 1461C1504.36 1461 1775 1239.16 1775 965.5C1775 691.843 1504.36 470 1170.5 470C836.644 470 566 691.843 566 965.5C566 1239.16 836.644 1461 1170.5 1461Z"
          fill="#F9BF85"
          className={styles["ball2"]}
        />
          <path
          ref={smallBall1Ref}
          d="M162.25 261.5C333.32 261.5 472 150.579 472 13.75C472 -123.079 333.32 -234 162.25 -234C-8.82022 -234 -147.5 -123.079 -147.5 13.75C-147.5 150.579 -8.82022 261.5 162.25 261.5Z"
          fill="#F9BF85"
          className={styles["smallBall1"]}
        />
        <path
          style={{opacity: 0}}
          ref={smallBall2Ref}
          d="M585.25 730.5C752.18 730.5 887.5 619.579 887.5 482.75C887.5 345.921 752.18 235 585.25 235C418.32 235 283 345.921 283 482.75C283 619.579 418.32 730.5 585.25 730.5Z"
          fill="#F9BF85"
          className={styles["smallBall2"]}
        />
      </svg>
    </div>
  );
};

export default AuthBackground;
