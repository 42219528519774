import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OTPInput from '../../components/inputs/OTPInput';
import SubmitButton from '../../components/buttons/SubmitButton';
import { AuthContext } from '../../contexts/AuthContext';
import { toast } from "react-toastify";
import styles from './OtpVerification.module.css';

function OtpVerification() {
  const { verifyOtp, sendOtp } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); 
  const email = location.state?.email; 
  const [otp, setOtp] = useState('');
  const [resendCountdown, setResendCountdown] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(false);

  const handleVerifyOtp = async () => {
    try {
      if (!email) {
        toast.error("Email not found. Please go back and enter your email again.");
        return;
      }
      const response = await verifyOtp(email, otp);
      if (response.message === "success") {
        toast.success("Code verified successfully");
        navigate('/reset-password');
      } 
    } catch (err) {
      toast.error("Failed to verify code: " + err.message);
      console.error("Failed to verify code:", err);
    }
  };

  const handleResend = async () => {
    try {
      if (!email) {
        toast.error("Email not found. Please go back and enter your email again.");
        return;
      }
      setResendDisabled(true);
      setResendCountdown(30);
      await sendOtp(email);
      toast.success("verify code resent successfully");

      const interval = setInterval(() => {
        setResendCountdown(prev => {
          if (prev === 1) {
            clearInterval(interval);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (err) {
      toast.error("Failed to resend verification code: " + err.message);
      setResendDisabled(false);
      console.error("Failed to resend verification code:", err);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Enter your OTP sent to your email</h1>
      <OTPInput numInputs={5} onComplete={setOtp} autoFocus />
      <div className={styles.buttons}>
      <SubmitButton
        className={styles.submitButton}
        onClick={handleVerifyOtp}
        text="Verification Code"
      />

<SubmitButton
        onClick={handleResend}
        disabled={resendDisabled}
        text={resendDisabled ? `Resend verification code in ${resendCountdown}s` : 'Resend verification code'}
      />
</div>
      
    </div>
  );
}

export default OtpVerification;
