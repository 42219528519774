import React from "react";
import styles from "./BillOfMaterial.module.css";

const BillOfMaterial = ({ category, billItems, onDetailsClick }) => {
  const totalCostRange = billItems.reduce(
    (acc, item) => {
      acc.low += item.unit_cost_low * item.quantity;
      acc.high += item.unit_cost_high * item.quantity;
      return acc;
    },
    { low: 0, high: 0 }
  );

  const workers = billItems.length; 

  return (
    <div className={styles.container}>
      <div className={styles.header}>{category}</div>
      <div className={styles.detail}>
        <div className={styles.title}>Total Estimated Cost:</div>
        <div className={styles.cost}>
          ${totalCostRange.low} - ${totalCostRange.high}
        </div>
      </div>
      <div className={styles.detail}>
        <div className={styles.title}>Required Workers:</div>
        <div className={styles.workers}>{workers}</div>
      </div>
      <button className={styles.detailsButton} onClick={onDetailsClick}>
        Details
      </button>
    </div>
  );
};

export default BillOfMaterial;
