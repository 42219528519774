import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import {
  addElement as addElementService,
  getElement as getElementService,
  deleteElement as deleteElementService,
  reorderElement as reorderElementService,
  updateElement as updateElementService,
  addImage as addImageService,
} from "../services/portfolioService";

const PortfolioContext = createContext();

export const PortfolioProvider = ({ children }) => {
  const [elements, setElements] = useState([]);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingElement, setPendingElement] = useState(null); 
  const [isEditing, setIsEditing] = useState(false);
  const [updatingElement, setUpdatingElement] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getElementService();
        const data = response.data;
        if (data) {
          setElements(data || []);
          console.log('Portfolio fetched successfully:', data);
        }
      } catch (error) {
        console.error('Error fetching portfolio:', error);
      }
    };
    fetchData();

    return () => {
      if (autoSaveTimeout) {
        clearTimeout(autoSaveTimeout);
      }
    };
  }, []);

  const triggerAutoSave = () => {
    if (autoSaveTimeout) {
      clearTimeout(autoSaveTimeout);
    }
    const timeoutId = setTimeout(() => {
      savePortfolio();
    }, 2000);
    setAutoSaveTimeout(timeoutId);
  };

  const openModalForNewElement = (element) => {
    setIsEditing(false);
    setPendingElement(element); 
    setSelectedElementIndex(elements.length); 
    setIsModalOpen(true);
  };

  const addElement = (element) => {
    openModalForNewElement(element); 
  };
  const openModalForExistingElement = (index) => {
    setIsEditing(true);
    setPendingElement(index);
    setSelectedElementIndex(index);
    setIsModalOpen(true);
  }

  const saveElement = async (data) => {
    if (data) {
      const newElement = { ...data, order: elements.length + 1 };

      setElements((prevElements) => [...prevElements, newElement]);

      try {
        const res = await addElementService(newElement);
        return res;
      } catch (error) {
        console.error('Error adding element:', error);
      }

      triggerAutoSave();
      setPendingElement(null); 
    }
  };

  const updateElementData = async (index, updatedElement) => {
    const updatedElements = elements.map((el, i) =>
      i === index ? { ...el, ...updatedElement } : el
    );
    setElements(updatedElements);
    triggerAutoSave();

    try {
      await updateElementService(updatedElement);
      console.log('Element updated successfully:', updatedElement);
    } catch (error) {
      console.error('Error updating element:', error);
    } finally {
      setPendingElement({});
      setUpdatingElement(null);
      setIsEditing(false);
    }
  };

  const debouncedReorder = useRef(debounce(async (updatedElements) => {
    try {
      await reorderElementService(updatedElements);
      console.log('Elements reordered successfully');
    } catch (error) {
      console.error('Error reordering elements:', error);
    }
  }, 500)).current;

  const moveElement = async (fromIndex, toIndex) => {
    const updatedElements = [...elements];
    const [movedElement] = updatedElements.splice(fromIndex, 1);
    updatedElements.splice(toIndex, 0, movedElement);

    updatedElements.forEach((el, i) => {
      el.order = i + 1;
    });

    setElements(updatedElements);

    debouncedReorder(updatedElements);
  };

  const removeElement = async (index) => {
    try {
      const elementToRemove = elements[index];

      setElements((prevElements) => {
        const updatedElements = prevElements.filter((_, i) => i !== index);
        return updatedElements.map((el, i) => ({
          ...el,
          order: i + 1,
        }));
      });

      if (selectedElementIndex === index) {
        setSelectedElementIndex(null);
        setIsModalOpen(false);
      } else if (selectedElementIndex > index) {
        setSelectedElementIndex((prevIndex) => prevIndex - 1);
      }

      await deleteElementService(elementToRemove.id);
      console.log('Element removed successfully:', elementToRemove);

      triggerAutoSave();
    } catch (error) {
      console.error('Error removing element:', error);
    }
  };

  const savePortfolio = async () => {
    try {
      setIsSaving(true);
      await addElementService(elements);
      console.log('Portfolio saved successfully');
    } catch (error) {
      console.error('Error saving portfolio:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const saveImage = async (image) => {
    try {
      setIsSaving(true);
      const res = await addImageService(image);
      console.log('Image saved successfully:', res);
    } catch (error) {
      console.error('Error saving image:', error);
    } finally {
      setIsSaving(false);
    }
  };
  const openModal = (index) => {
    setSelectedElementIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <PortfolioContext.Provider
      value={{
        elements,
        selectedElementIndex,
        setSelectedElementIndex,
        openModalForExistingElement,
        addElement,
        isEditing,
        setPendingElement,
        saveElement,
        updateElementData,
        moveElement,
        pendingElement,
        removeElement,
        openModal,
        closeModal,
        savePortfolio,
        isSaving,
        isModalOpen,
        saveImage,
        setUpdatingElement,
        updatingElement
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolio = () => {
  const context = useContext(PortfolioContext);
  if (!context) {
    throw new Error('usePortfolio must be used within a PortfolioProvider');
  }
  return context;
};

export const withPortfolio = (Component) => (props) => {
  const context = usePortfolio();
  return <Component {...props} {...context} />;
};