import React from "react";
import styles from "./SubmitButton.module.css";

const SubmitButton = ({ text, onClick, disabled }) => {
  return (
    <div
      type="submit"
      className={`${styles.submitButton} width--full`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </div>
  );
};

export default SubmitButton;
