import React, { useEffect, useState } from 'react';
import { usePortfolio } from '../../../contexts/PortfolioContext';
import styles from './ElementProperties.module.css';
import Upload from '../../Upload';

const ElementProperties = () => {
  const { elements, selectedElementIndex, saveElement, pendingElement, closeModal, isEditing, updatedElementData, updateElementData, updatingElement } = usePortfolio();
  const [properties, setProperties] = useState({});
  console.log(properties, selectedElementIndex)
  useEffect(() => {
    if (selectedElementIndex !== null) {
      if(!updatingElement){
        setProperties(elements[selectedElementIndex])
      }
      if (updatingElement){
        setProperties(elements[updatingElement])
        console.log(elements,updatingElement ,properties, selectedElementIndex)
      }
      if (pendingElement && !updatingElement) {
        console.log('ha?')
        setProperties(pendingElement);
      } else if (elements[selectedElementIndex]) {
        setProperties(elements[selectedElementIndex]);
      }
    }
  }, [selectedElementIndex, elements, pendingElement, updatingElement]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProperties(prevProps => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSave = () => {
    if (selectedElementIndex !== null) {
      isEditing ? updateElementData(selectedElementIndex, properties) : saveElement(properties);
    }
    closeModal()
  };
 
  if (selectedElementIndex === null) {
    return <p>Select an element to edit its properties.</p>;
  }
  if(!properties || properties.tag_type === undefined) {
    return <p>No properties available for the selected element.</p>;
  }
  const renderPropertiesForm = () => {
    switch (properties.tag_type) {
      case 'p':
        return (
          <div className={styles.propertyGroup}>
            <label>
              Text:
              <input
                type="text"
                name="value"
                value={properties.value || ''}
                onChange={handleChange}
              />
            </label>
          </div>
        );

      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        return (
          <div className={styles.propertyGroup}>
            <label>
              Heading Text:
              <input
                type="text"
                name="value"
                value={properties.value || ''}
                onChange={handleChange}
              />
            </label>
            <label>
              Tag Type:
              <select
                name="tag_type"
                value={properties.tag_type || 'h1'}
                onChange={handleChange}
              >
                <option value="h1">H1</option>
                <option value="h2">H2</option>
                <option value="h3">H3</option>
                <option value="h4">H4</option>
                <option value="h5">H5</option>
                <option value="h6">H6</option>
              </select>
            </label>
          </div>
        );

      case 'img':
        return (
          <div className={styles.propertyGroup}>
            <Upload properties closeModal />
          </div>
        );

      default:
        console.log('No properties available for the selected element type:', properties.tag_type);
        return <p>No properties available for the selected element type: {properties.tag_type}</p>;
    }
  };

  return (
    <div className={styles.elementProperties}>
      <h3>Element Properties</h3>
      {renderPropertiesForm()}
      
      {isEditing  ?( 
  <button onClick={() => handleSave(selectedElementIndex, updatedElementData)}>
    Save Changes
  </button>
) : 

(
  <>
  {properties.tag_type !== 'img' && (
        <>
        <button onClick={() => saveElement(properties)}>
    Add Element
  </button></>
      )}
  </>
  
)}
    </div>
  );
};

export default ElementProperties;