import React from "react";
import { Link } from "react-router-dom";
import styles from "./SplashPage.module.css";

function SplashPage() {
  return (
    <div className={styles["splash-container"]}>
      <div className={styles["splash-content"]}>
        <h1 className={styles["splash-title"]}>Welcome to Our App!</h1>
        <p className={styles["splash-subtitle"]}>Your journey starts here.</p>
        <div className={styles["button-container"]}>
          <Link to="/login" className={styles["splash-button"]}>
            Login
          </Link>
          <Link to="/register" className={styles["splash-button"]}>
            Register
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SplashPage;
