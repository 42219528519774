import React from "react";
import PropTypes from "prop-types";
import styles from "./TextInput.module.css";

const TextInput = React.forwardRef(
  ({ defaultValue, label, placeholder, error, type = "text", name, register }, ref) => (
    <div className={styles.inputWrapper}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        type={type}
        ref={ref}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={`${styles.input} ${error ? styles.inputError : ""}`}
        {...register?.(name)}
      />
      {error && <span className={styles.errorMessage}>{error.message}</span>}
    </div>
  )
);

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.object,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
};

export default TextInput;
