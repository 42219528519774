import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styles from "./AddMember.module.css";
import TextInput from "../inputs/TextInput";
import SelectInput from "../inputs/SelectInput";
import AddButton from "../buttons/AddButton";
import { toast } from "react-toastify";

const AddMember = ({ onAdd, onUpdate, editingMember, humanResources }) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  
  const existMembers = humanResources?.map((member) => ({
    employee_full_name: member.employee_full_name,
    expertise: member.expertise,
    exp_year: member.exp_year,
  }));
  useEffect(() => {
    if (editingMember) {
      setValue("employee_full_name", editingMember.employee_full_name);
      setValue("expertise", editingMember.expertise);
      setValue("exp_year", editingMember.exp_year);
    } else {
      reset();
    }
  }, [editingMember, setValue, reset]);
  

  const onSubmit = (data) => {
  
    const newMember = {
      employee_full_name: data.employee_full_name,
      expertise: data.expertise,
      exp_year: data.exp_year,
    };

    const sendData = {
      human_resources: [...existMembers, newMember],
    };

    if (editingMember) {
      onUpdate({ ...editingMember, ...data });
    } else {
      onAdd(sendData);
    }
    
    reset();
  };

  return (
    <div className={`${styles.form} grid--1`}>
      <form
        className={`grid--3 width--full grid--m-1 gap--xs`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextInput
          placeholder="Employee's Name"
          name="employee_full_name"
          label="Full Name"
          register={register}
        />
        <SelectInput
          label="Expertise"
          name="expertise"
          register={register}
          options={[
            { value: "", label: "Select Expertise" },
            { value: "Plumber", label: "Plumber" },
            { value: "Electrician", label: "Electrician" },
            { value: "Carpenter", label: "Carpenter" },
            { value: "Mason", label: "Mason" },
            { value: "Painter", label: "Painter" },
            { value: "Drywaller", label: "Drywaller" },
            { value: "Roofer", label: "Roofer" },
            { value: "HVAC Technician", label: "HVAC Technician" },
            { value: "Flooring Installer", label: "Flooring Installer" },
            { value: "Demolition Worker", label: "Demolition Worker" },
            { value: "Welder", label: "Welder" },
            { value: "Concrete Finisher", label: "Concrete Finisher" },
            { value: "Glazier", label: "Glazier" },
            { value: "Insulation Installer", label: "Insulation Installer" },
            { value: "Tiler", label: "Tiler" },
          ]}
        />
        <SelectInput
          label="Years of Experience"
          name="exp_year"
          register={register}
          options={[
            { value: "", label: "Select Years of Experience" },
            { value: 1, label: "1 Year" },
            { value: 2, label: "2 Years" },
            { value: 3, label: "3 Years" },
            { value: 4, label: "4 Years" },
            { value: 5, label: "5 Years" },
          ]}
        />
        <AddButton
          onClick={handleSubmit(onSubmit)}
          type="submit"
          text={editingMember ? "Update" : "Add"}
        />
      </form>
    </div>
  );
};

export default AddMember;
