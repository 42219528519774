import React from "react";
import styles from "./GlobalLoader.module.css";
import { BarLoader } from "react-spinners";

const GlobalLoader = () => {
  return (
    <div className={styles[`global-loader`]}>
      <BarLoader
  color="#ff7f00"
  height={4}
  width={200}
/>
    </div>
  );
};

export default GlobalLoader;
