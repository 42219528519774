import React, { useState } from "react";
import PropTypes from "prop-types";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import styles from "./PasswordInput.module.css";

const PasswordInput = React.forwardRef(
  ({ label, placeholder, error, iconPosition = "after", ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    return (
      <div className={styles.inputWrapper}>
        {label && <label className={styles.label}>{label}</label>}
        <div
          className={`${styles.passwordField} ${
            iconPosition === "before" ? styles.iconBefore : styles.iconAfter
          }`}
        >
          {/* Eye icon before input */}
          {iconPosition === "before" && (
            <span className={styles.eyeIcon} onClick={togglePasswordVisibility}>
              {isPasswordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
          )}

          <input
            type={isPasswordVisible ? "text" : "password"}
            ref={ref}
            placeholder={placeholder}
            className={`${styles.input} ${error ? styles.inputError : ""}`}
            {...props}
          />

          {/* Eye icon after input */}
          {iconPosition === "after" && (
            <span className={styles.eyeIcon} onClick={togglePasswordVisibility}>
              {isPasswordVisible ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
          )}
        </div>
        {error && <span className={styles.errorMessage}>{error}</span>}
      </div>
    );
  }
);

PasswordInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  iconPosition: PropTypes.oneOf(["before", "after"]),
};

export default PasswordInput;
