import React from "react";
import ProjectList from "../../components/ProjectList";
import UploadRFQFile from "../../components/dashboard/UploadRFQFile";
import { ProjectProvider } from "../../contexts/ProjectContext";
import style from "./Dashboard.module.css";
const Dashboard = () => {
  return (
    <>
      <ProjectProvider>
        <div className={style.row}>
          <UploadRFQFile />
        </div>
        <div className={style.row}>
          <ProjectList />
        </div>
      </ProjectProvider>
    </>
  );
};

export default Dashboard;
