import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Project.module.css";
import ProjectTitle from "../../components/project/ProjectTitle";
import ProjectContent from "../../components/project/ProjectContent";
import ProjectProposal from "../../components/project/ProjectProposal";
import { ProjectContext } from "../../contexts/ProjectContext";
const Project = () => {
  const location = useLocation();
  const { project, fetchProjectData, toggleSidebar, sidebarOpen, editProjectDetails, setEditProjectDetails } =
    useContext(ProjectContext);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("project-id");

    if (projectId) {
      fetchProjectData(projectId);
    }
  }, [location.search]);
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ProjectTitle title={project?.title} projectId={project?.id} />
        {!editProjectDetails &&  <><div onClick={()=>{setEditProjectDetails(!editProjectDetails)}} className={styles.editTitle}>
            Edit
            </div>
          <div
          onClick={(e) => {
            e.preventDefault();
            toggleSidebar();
          }}
          className={styles.toggle}
        >
          {(sidebarOpen ? "Close Sidebar" : "View Details")}
          
        </div>
        </>}
       
      </div>
      <ProjectContent content={project?.description} />
      <ProjectProposal proposal={project?.proposal} id={project?.id} />
    </div>
  );
};

export default Project;
