import React from "react";
import PropTypes from "prop-types";
import styles from "./Sidebar.module.css";

const Sidebar = ({ children, isOpen }) => {
  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ""}`}>
      <div className={`${styles.content} `}>{children}</div>
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
};

export default Sidebar;
