import React, { useEffect, useContext, useCallback } from "react";
import { useForm } from "react-hook-form";
import styles from "./ForgotPassword.module.css";
import SubmitButton from "../../components/buttons/SubmitButton";
import EmailInput from "../../components/inputs/EmailInput";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../contexts/AuthContext';
import { toast } from "react-toastify";

function ForgotPassword() {
  const { sendOtp } = useContext(AuthContext);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const sendCode = useCallback(async (data) => {
    try {
      if (!data.email) {
        toast.error("Email is required");
        return;
      }
      const response = await sendOtp(data.email);
      toast.success(response);

      if (response !== undefined) {
        navigate('/otp-verification', { state: { email: data.email } });
      }
    } catch (err) {
      toast.error("Failed to send verify code: " + err.message);
      console.error("Failed to send verify code:", err);
    }
  }, []);
    
  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        handleSubmit(sendCode)();
      }
    };

    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [handleSubmit, sendCode]);

  return (
    <div className="pad--xxl">
      <h2>You Forgot Password!</h2>
      <p>Please Enter your email address to reset your password</p>
      <div className={styles.form}>
        <EmailInput
          type="email"
          label="Email Address"
          placeholder="Please enter your email address"
          error={errors.email?.message}
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Please enter a valid email address",
            },
          })}
        />
        <SubmitButton text="Next" onClick={handleSubmit(sendCode)} />
        <Link to="/login" className={`${styles.skip} center--all pad--s`}>
          Back
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
