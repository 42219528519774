import React from "react";
import styles from "./ProjectContent.module.css";

const ProjectContent = ({ content }) => {
  return (
    <div className={styles.container}>
      <h3>Project Overview</h3>
      <p
          className={styles.descriptionText}
          dangerouslySetInnerHTML={{ __html: content }}
        />

    </div>
  );
};

export default ProjectContent;
