import colors from "./colors";
import spacing from "./spacing";
import typography from "./typography";

const lightTheme = {
  background: colors.light,
  text: colors.dark,
  primary: colors.primary,
  spacing,
  typography,
};

const darkTheme = {
  background: colors.dark,
  text: colors.light,
  primary: colors.primary,
  spacing,
  typography,
};

export { lightTheme, darkTheme };
