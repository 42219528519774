import React, { useState } from "react";
import styles from "./CompleteInfo.module.css";
import logo from "../../assets/images/Estimaze-register-logo.png";
import AccountTypeRadio from "../../components/inputs/AccountTypeRadio";
import { ReactComponent as PersonalIcon } from "../../assets/icons/user.svg";
import { ReactComponent as CompanyIcon } from "../../assets/icons/building.svg";
import PersonalAccountForm from "../../components/auth/PersonalAccountForm";
import CompanyAccountForm from "../../components/auth/CompanyAccountForm";

function CompleteInfo() {
  const [accountType, setAccountType] = useState("personal");

  return (
    <div className={`${styles.authContainer} pad--xl`}>
      <div className={`center--all width--full`}>
        <img
          src={logo}
          alt="Estimaze"
          className={`${styles.logo} center--all width--full`}
        />
      </div>
      <div className={styles.formGroup}>
        <AccountTypeRadio
          id="personal"
          
          label="Personal Account"
          icon={<PersonalIcon />}
          checked={accountType === "personal"}
          onChange={() => setAccountType("personal")}
        />
        <AccountTypeRadio
          id="company"
          label="Company Account"
          icon={<CompanyIcon />}
          checked={accountType === "company"}
          onChange={() => setAccountType("company")}
        />
      </div>
      <div className={styles.authRight}>
        <h2 className="center--all">Please enter your personal information</h2>
        {accountType === "personal" ? (
          <PersonalAccountForm />
        ) : (
          <CompanyAccountForm />
        )}
      </div>
    </div>
  );
}

export default CompleteInfo;
