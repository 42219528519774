import React, { useContext, useState, useEffect } from "react";
import styles from "./ProjectTitle.module.css";
import { ProjectContext } from "../../../contexts/ProjectContext";

const ProjectTitle = ({ title: initialTitle, projectId }) => {
  const { editProjectDetails, handleEditProject } = useContext(ProjectContext);
  const [title, setTitle] = useState(initialTitle || "");

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);
  const handleSave = () => {
    handleEditProject(projectId, { title });
  };

  if (editProjectDetails) {
    return (
        <>
        <input
          type="text"
          placeholder="Project Title"
          className={styles.wrapper}
          value={title}
          onChange={handleChange}
        />
        <div className={styles.save} onClick={handleSave}>Save</div>
        </>
     
    );
  }

  return (
    <input
      className={styles.wrapper}
      type="text"
      placeholder="Project Title"
      value={title}
      readOnly
    />
  );
};

export default ProjectTitle;
