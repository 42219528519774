// src/api/apiClient.js

import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api.estimaze.org/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  // Check for the custom header flag to determine whether to include the Authorization header
  if (config.headers.includeAuth) {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }
    delete config.headers.includeAuth; // Remove the custom header flag
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API error:", error);
    return Promise.reject(error);
  }
);

export default apiClient;
