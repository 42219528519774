import React, { useContext } from "react";
import ProjectItem from "../ProjectItem";
import styles from "./ProjectList.module.css";
import { ProjectContext } from "../../contexts/ProjectContext";
import { ClimbingBoxLoader } from "react-spinners";

const ProjectList = () => {
  const { projects, loading } = useContext(ProjectContext);
  if (loading) {
    return <div className={styles.load}><ClimbingBoxLoader color="#ff7f00" /></div>
  }
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Project Title</th>
            <th>Cost</th>
            <th>Timeline</th>
            <th>Proposal Creation Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {projects.length === 0 ? (
            <tr>
              <td className={styles.empty} colSpan="5">
                No projects added
              </td>
            </tr>
          ) : (
            projects.map((project, index) => (
              <ProjectItem key={index} project={project} />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectList;
