import React, { useContext, useState } from "react";
import styles from "./ProjectSidebar.module.css";
import BillOfMaterial from "../BillOfMaterial";
import ScoreCard from "../ScoreCard";
import Modal from "../../common/Modal";
import BomDetail from "../BomDetail";
import { ProjectContext } from "../../../contexts/ProjectContext";

const ProjectSidebar = ({ isOpen, toggleSidebar }) => {
  const sidebarClass = isOpen ? styles.open : styles.closed;
  const [showModal, setShowModal] = useState(false);
  const { project } = useContext(ProjectContext);

  const handleDetailsClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={`${styles.container} ${sidebarClass} `}>
      {project && (
        <>
          {project.bill_of_materials.map((bill, index) => (
            <BillOfMaterial
              key={bill.id}
              category={bill.category}
              billItems={bill.bill_items}
              onDetailsClick={handleDetailsClick}
            />
          ))}{" "}
          <Modal show={showModal} onClose={handleCloseModal}>
            <BomDetail
              billOfMaterials={project.bill_of_materials}
              billOfHumans={project.bill_of_humans}
            />
          </Modal>
          <ScoreCard score={project.resume_score} data={project} />
        </>
      )}
    </div>
  );
};

export default ProjectSidebar;
