import React, { useCallback, useContext, useEffect } from "react";
import EmailInput from "../../inputs/EmailInput";
import NumberInput from "../../inputs/NumberInput";
import PasswordInput from "../../inputs/PasswordInput";
import SubmitButton from "../../buttons/SubmitButton";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function RegisterForm() {
  const { registerUser, loading, error: authError } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(async (data) => {
    const { confirmPassword, ...formData } = data;
    try {
      const response = await registerUser(formData);
      toast.warning(response)
      console.log("Registration response:", response);
      navigate("/upload-profile-picture");
    } catch (error) {
      toast.error(error.message)
      console.error("Registration error:", error.message);
    }
  }, [registerUser, navigate]);

  const password = watch("password");
  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        handleSubmit(onSubmit)();
      }
    };

    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [handleSubmit, onSubmit]);
  return (
    <form className="register-form">
      <EmailInput
        label="Email Address"
        placeholder="Enter your email"
        {...register("email", {
          required: "Email is required",
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Email is not valid",
          },
        })}
        error={errors.email?.message}
      />
     <NumberInput
        label="Phone"
        type="tel"
        placeholder="Enter your phone number"
        {...register("phone", {
          required: "Phone number is required",
          pattern: {
            value: /^[0-9]{10,15}$/,
            message: "Phone number is not valid",
          },
          maxLength: {
            value: 15,
            message: "Ensure this field has no more than 15 characters",
          },
        })}
        error={errors.phone?.message}
      />
      <div className="grid--2 gap--xs grid--s-1">
        <PasswordInput
          label="Password"
          placeholder="Enter your password"
          {...register("password", { required: "Password is required" })}
          error={errors.password?.message}
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Enter your password again"
          {...register("confirmPassword", {
            required: "Confirm password is required",
            validate: (value) => value === password || "Passwords do not match",
          })}
          error={errors.confirmPassword?.message}
        />
      </div>
      <SubmitButton
        text={loading ? "Registering..." : "Register"}
        type="submit"
        disabled={loading}
        onClick={handleSubmit(onSubmit)}
      />
      {/* {authError && <p className="error">{authError}</p>} */}
    </form>
  );
}

export default RegisterForm;
