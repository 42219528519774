import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./publicRoutes";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Register from "../pages/Register";
import SplashPage from "../pages/SplashPage";
import AuthLayout from "../layouts/AuthLayout";
import CompleteInfo from "../pages/CompleteInfo";
import UploadProfilePicture from "../pages/UploadProfilePicture";
import ForgotPassword from "../pages/ForgotPassword";
import NotFound from "../pages/NotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Profile from "../pages/Profile";
import Portfolio from "../pages/Portfolio";
import HumanResource from "../pages/HumanResource";
import AddHumanResource from "../pages/AddTeamMember";
import Project from "../pages/Project";
import ProjectLayout from "../layouts/ProjectLayout";
import OtpVerification from "../pages/OtpVerification"
import ResetPassword from "../pages/ResetPassword";

const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<SplashPage />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="otp-verification" element={<OtpVerification />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="profile" element={<DashboardLayout />}>
          <Route index element={<Profile />} />
        </Route>
        <Route path="human-resource" element={<DashboardLayout />}>
          <Route index element={<HumanResource />} />
        </Route>
        <Route path="portfolio" element={<DashboardLayout />}>
          <Route index element={<Portfolio />} />
        </Route>
        <Route path="project" element={<DashboardLayout />}>
          <Route element={<ProjectLayout />}>
            <Route index element={<Project />} />{" "}
          </Route>
        </Route>
        <Route path="/" element={<AuthLayout />}>
          <Route path="add-human-resource" element={<AddHumanResource />} />
          <Route
            path="upload-profile-picture"
            element={<UploadProfilePicture />}
          />
          <Route path="complete-info" element={<CompleteInfo />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
