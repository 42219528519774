import React from "react";
import TextInput from "../../../components/inputs/TextInput";
import DateInput from "../../../components/inputs/DateInput";
import SelectInput from "../../../components/inputs/SelectInput";
import FileInput from "../../../components/inputs/FileInput";
import styles from "./PersonalAccountForm.module.css";
import NumberInput from "../../inputs/NumberInput";
import { AuthContext } from "../../../contexts/AuthContext";
import { useForm } from "react-hook-form";

const PersonalAccountForm = ({ data }) => {
  const { updateResume } = React.useContext(AuthContext);
  const userInfo = data?.user_info;
  const {
    register,
    formState: { errors },
  } = useForm();
  
  const handleResumeChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await updateResume(file);
    }
  };


  return (
    <div className={styles.form}>
      <div className="grid--3 gap--xs grid--s-1 grid--m-2">
        <TextInput
          label="Full Name"
          placeholder="Enter your full name"
          defaultValue={userInfo?.full_name}
          name="full_name"
          register={register}
          error={errors?.full_name}
        />
        <DateInput
          label="Date of Birth"
          placeholder="Enter your date of birth"
          defaultValue={userInfo?.date_of_birth}
          name="date_of_birth"
          {...register("date_of_birth", {
            required: "Date of birth is required",
          })}
          error={errors?.date_of_birth}
        />
        <SelectInput
          label="Gender"
          name="gender"
          defaultValue={userInfo?.gender}
          register={register}
          options={[
            { value: "", label: "Select your gender" },
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
            { value: "prefer_not_to_say", label: "Prefer not to say" },
          ]}
          error={errors?.gender}
        />
      </div>
      <div className="grid--2 gap--xs grid--s-1">
        <NumberInput
          label="Years of Experience"
          placeholder="Enter your Years of Experience"
          defaultValue={userInfo?.exp_year}
          name="exp_year"
          {...register("exp_year", {
            maxLength: {
              value: 2,
              message: "Ensure this field has no more than 2 characters",
            },
          })}
          error={errors?.exp_year?.message}
        />
        <TextInput
          label="Field of Experience"
          placeholder="Enter your field of experience"
          defaultValue={userInfo?.exp_field}
          name="exp_field"
          register={register}
          error={errors?.exp_field}
        />
      </div>
      <div className="grid--2 gap--xs grid--s-1">
        <SelectInput
          label="Country"
          name="country"
          defaultValue={userInfo?.country}
          register={register}
          options={[
            { value: "Canada", label: "Canada" },
            { value: "USA", label: "United States of America" },
            { value: "UK", label: "United Kingdom" },
          ]}
          error={errors?.country}
        />
        <TextInput
          label="City"
          placeholder="Enter your city"
          defaultValue={userInfo?.city}
          name="city"
          register={register}
          error={errors?.city}
        />
      </div>
      <TextInput
        label="Address"
        placeholder="Enter your address"
        defaultValue={userInfo?.address}
        name="address"
        register={register}
        error={errors?.address}
      />
      <div className="grid--1 gap--xs grid--s-1">
        {userInfo?.resume ? (
          <div>
            <p>Current Resume:</p>
           
            <a
              href={userInfo.resume}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.resumeLink}
            >
              Download Current Resume
            </a>
            <p>Or upload a new resume:</p>
          </div>
        ) : (
          <p>No resume uploaded. Please upload your resume:</p>
        )}
        <FileInput
          label="Resume"
          name="resume"
          accept="application/pdf"
          onChange={handleResumeChange}
          error={errors?.resume}
        />
      </div>
    </div>
  );
};

export default PersonalAccountForm;