import apiClient from "../api/apiClient";

export const addHumanResource = async (resource) => {
  try {
    const response = await apiClient.put("/account/human-resource/", resource, {
      headers: {
        includeAuth: true,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to add human resource:", error);
    throw error;
  }
};



