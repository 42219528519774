import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../contexts/AuthContext';
import SubmitButton from '../../components/buttons/SubmitButton';
import PasswordInput from '../../components/inputs/PasswordInput';
import { toast } from 'react-toastify';
import styles from './ResetPassword.module.css';

function ResetPassword() {
  const { resetPassword } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await resetPassword(data.password);
      toast.success("Password reset successfully.");
      navigate('/login');
    } catch (err) {
      toast.error("Failed to reset password: " + err.message);
      console.error("Failed to reset password:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Reset Your Password</h2>
      <form className={styles.form}>
        <PasswordInput
          label="New Password"
          placeholder="Enter your new password"
          error={errors.password?.message}
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long'
            }
          })}
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm your new password"
          error={errors.confirmPassword?.message}
          {...register('confirmPassword', {
            validate: value => value === watch('password') || 'Passwords do not match'
          })}
        />
        <SubmitButton text={loading ? 'Resetting...' : 'Reset Password'} disabled={loading} onClick={handleSubmit(onSubmit)} />
      </form>
    </div>
  );
}

export default ResetPassword;
