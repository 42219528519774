import React from "react";
import styles from "./CompleteProfileStatus.module.css";
import useAuth from "../../../hooks/useAuth";

function CompleteProfileStatus() {
  const { user } = useAuth();
  const fields = [
    { name: "Name", completed: user?.user_info?.full_name },
    {
      name: "Location",
      completed: user?.user_info?.country || user?.user_info?.city,
    },
    { name: "Age", completed: user?.user_info?.date_of_birth },
    { name: "Specialty", completed: user?.user_info?.exp_field },
    { name: "Human Resource", completed: user?.human_resources?.length > 0 },
    {
      name: "Resume",
      completed: user?.user_info?.resume || user?.user_info?.resume_text,
    },
    {
      name: "Portfolio",
      completed: user?.user_info?.portfolio || user?.user_info?.portfolio_text || user?.user_info?.portfolio_done,
    },
  ];

  const completedCount = fields.filter((field) => field.completed)?.length;
  const totalFields = fields?.length;
  const progressPercentage = (completedCount / totalFields) * 100;
  if (progressPercentage === 100) {
    return 
  }
  return (
    <div className={styles.container}>
      <h4 className={styles.h4}>Profile Completion</h4>
      <div className={styles.progressContainer}>
        <svg className={styles.progressCircle} viewBox="0 0 36 36">
          <path
            className={styles.circleBg}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className={styles.circle}
            strokeDasharray={`${progressPercentage}, 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className={styles.progressText}>
            {completedCount} of {totalFields}
          </text>
        </svg>
      </div>
      <div className={styles.infos}>
        {fields.map((field, index) => (
          <div key={index} className={styles.info}>
            <div
              className={
                field.completed ? styles.infoValueCompleted : styles.infoValue
              }
            ></div>
            <div className={styles.infoText}>{field.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompleteProfileStatus;
