import React, { createContext, useState, useEffect } from "react";
import {
  fetchProjects,
  uploadRFQ,
  deleteProject,
  editProject,
  fetchProject,
  generateProposalFn
} from "../services/projectService";
import { toast } from "react-toastify";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState();
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [editProjectDetails, setEditProjectDetails] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  

  const loadProjects = async () => {
    setLoading(true);
    try {
      const data = await fetchProjects();
      setProjects(data.data);
      return projects;
    } catch (error) {
      console.error("Failed to load projects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProjects();
  }, []);

  const handleUploadRFQ = async (rfqFile) => {
    setLoading(true);
    try {
      const res = await uploadRFQ(rfqFile);
      loadProjects();
      return res;
    } catch (error) {
      setLoading(false);
  
      let errorMessage = "An unexpected error occurred";
  
      if (error.response) {
        errorMessage = error.response.data?.message || `Server error: ${error.response.status}`;
      } else if (error.request) {
        errorMessage = "No response received from the server.";
      } else {
        errorMessage = error.message;
      }
      
      toast.error(`Error uploading RFQ: ${errorMessage}`, {
        position: toast.POSITION.TOP_RIGHT
      });
  
      throw error; // Re-throw the error for further handling if needed
    } finally {
      setLoading(false);
    }
  };
  
  const generateProposal = async (projectId) => {
    setLoading(true);
    try {
      const res = await generateProposalFn(projectId);
      if (res.message === "success") {
        const updatedProject = await fetchProject(projectId);
        setProject(updatedProject.data);
  
        // Update the list of projects with the new data
        setProjects((prevProjects) =>
          prevProjects.map((proj) =>
            proj.id === projectId ? { ...proj, ...updatedProject.data } : proj
          )
        );
      }
    } catch (error) {
      toast.error("Failed to generate proposal");
    } finally {
      setLoading(false);
    }
  };
  

  // const generateProposal = async (projectId) => {
  //   setLoading(true);
  //   try {
  //     const res = await generateProposalFn(projectId);
  //     if (res.message === "success") {
  //       fetchProject(projectId);
  //     }
  //     fetchProject(projectId);
  //     loadProjects();
  //   } catch (error) {
  //     toast.error("Failed to generate proposal");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDeleteProject = async (projectId) => {
    setLoading(true);
    try {
      await deleteProject(projectId);
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );
      loadProjects();
    } catch (error) {
      console.error("Failed to delete project:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjectData = async (projectId) => {
    try {
      const response = await fetchProject(projectId);
      setProject(response.data);
    } catch (error) {
      console.error("Failed to fetch project data:", error);
    }
  };

  const handleEditProject = async (projectId, updatedData) => {
    setLoading(true);
    try {
      await editProject(projectId, updatedData);
      setProjects((prevProjects) =>
        prevProjects.map((proj) =>
          proj.id === projectId ? { ...proj, ...updatedData } : proj
        )
      );
      setProject((prevProject) =>
        prevProject?.id === projectId
          ? { ...prevProject, ...updatedData }
          : prevProject
      );
      setEditProjectDetails(false); 
    } catch (error) {
      console.error("Failed to edit project:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <ProjectContext.Provider
      value={{
        generateProposal,
        projects,
        loading,
        toggleSidebar,
        loadProjects,
        sidebarOpen,
        project,
        setProject,
        handleUploadRFQ,
        handleDeleteProject,
        fetchProjectData,
        handleEditProject,
        setEditProjectDetails,
        editProjectDetails
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
