import apiClient from "../api/apiClient";
import {
  AUTH_CHANGE_PIC,
  AUTH_GMAIL_LOGIN,
  AUTH_HUMAN_RESOURCE,
  AUTH_INFO,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_SEND_OTP,
  AUTH_CHANGE_PASS,
  AUTH_VERIFY_OTP,
  AUTH_RESET_PASS
} from "../api/endpoints";

export const login = async (username, password) => {
  try {
    const response = await apiClient.post(
      AUTH_LOGIN,
      { username, password },
      {
        headers: {
          includeAuth: false,
        },
      }
    );
    localStorage.setItem("token", response.data.token);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const sendOtp = async (email) => {
  try {
    const response = await apiClient.post(
      AUTH_SEND_OTP,
      {email},
      {
        headers: {
          includeAuth: false,
        }
      }
    )
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
}

export const verifyOtp = async (email, otp_code) => {
  try {
    const response = await apiClient.post(
      AUTH_VERIFY_OTP,
      {email, otp_code},
      {
        headers: {
          includeAuth: false,
        }
      }
    )
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
}


export const changePassword = async (newPass) => {
  try {
    const response = await apiClient.post(
      AUTH_CHANGE_PASS,
      {newPass},
      {
        headers: {
          includeAuth: true
        }
      }
    ) 
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
}

export const register = async (userData) => {
  try {
    const response = await apiClient.post(AUTH_REGISTER, userData, {
      headers: {
        includeAuth: false,
      },
    });
    localStorage.setItem("token", response.data.token);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const getProfile = async () => {
  try {
    const response = await apiClient.get(AUTH_INFO, {
      headers: {
        includeAuth: true,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const updateProfile = async (profileData) => {
  try {
    const response = await apiClient.put(AUTH_INFO, profileData, {
      headers: {
        includeAuth: true,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const deleteProfile = async () => {
  try {
    const response = await apiClient.delete(AUTH_INFO);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const changePic = async (formData) => {
  try {
    const response = await apiClient.post(AUTH_CHANGE_PIC, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        includeAuth: true,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const gmailLogin = async (token) => {
  try {
    const response = await apiClient.post(AUTH_GMAIL_LOGIN, { token });
    localStorage.setItem("token", response.data.token);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const updateHumanResource = async (data) => {
  try {
    const response = await apiClient.put(AUTH_HUMAN_RESOURCE, data);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};


export const updateUserName = async (full_name) => {
  try {
    const response = await apiClient.put(AUTH_INFO, { full_name }, {
      headers: {
        includeAuth: true,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

export const updateResume = async (resume) => {
  try {
    const response = await apiClient.put(AUTH_INFO, { resume }, {
      headers: {
        includeAuth: true,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
}

export const updatePortfolio = async (portfolio) => {
  try {
    const response = await apiClient.put(AUTH_INFO, { portfolio }, {
      headers: {
        includeAuth: true,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
}

export const resetPasswordService = async (resetToken, newPassword) => {
  try {
    const response = await apiClient.post(
      AUTH_RESET_PASS,
      {
        'token': resetToken || null, 
        'new_password': newPassword 
      },
      {
        headers: {
          includeAuth: true
        }
      }
    );

    
    return response;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

