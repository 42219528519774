import React, { useContext, useState } from "react";
import styles from "./AddTeamMember.module.css";
import TeamList from "../../components/TeamList";
import AddMember from "../../components/AddMember";
import { HRContext, HRProvider } from "../../contexts/HRContext";
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
const AddTeamMember = () => {
  const [editingIndex, setEditingIndex] = useState(null);
  const { handleAddHumanResource } = useContext(HRContext);
  const { user } = useContext(AuthContext);
  const [tempHumanResources, setTempHumanResources] = useState(user?.human_resources || []);
  const notify = (message) => toast(message);

  const handleAddTeamMember = async (newMember) => {
    
    if (!Array.isArray(newMember.human_resources) || newMember.human_resources.length === 0) {
      notify("No team member data provided");
      return;
    }
    
    const lastMember = newMember.human_resources[newMember.human_resources.length - 1];
  
    if (
      !lastMember?.employee_full_name ||
      !lastMember?.expertise ||
      !lastMember?.exp_year
    ) {
      notify("Complete the form to add a team member");
      return;
    }
  
    const human_resources = [...tempHumanResources, lastMember];
    setTempHumanResources(human_resources);
  
    try {
      await handleAddHumanResource({ human_resources });
      notify("Team member added successfully");
    } catch (error) {
      notify("Failed to add team member");
    }
  };
  
  
  const handleEditTeamMember = (index) => {
    setEditingIndex(index);
  };

  const handleUpdateTeamMember = async (updatedMember) => {
    const updatedResources = tempHumanResources.map((member, idx) =>
      idx === editingIndex ? updatedMember : member
    );

    setTempHumanResources(updatedResources);

    try {
      console.log(`human resources:` + updatedResources)
      await handleAddHumanResource({ human_resources: updatedResources });
      notify("Team member updated successfully");
      setEditingIndex(null);
    } catch (error) {
      notify("Failed to update team member");
    }
  };

  const handleDeleteTeamMember = async (index) => {
    const updatedResources = tempHumanResources.filter((_, idx) => idx !== index);
    setTempHumanResources(updatedResources);

    try {
      await handleAddHumanResource({ human_resources: updatedResources });
      notify("Team member deleted successfully");
    } catch (error) {
      notify("Failed to delete team member");
    }
  };

  return (
    <HRProvider>
    <div className={`${styles.form} pad--l`}>
      <div className="grid--1">
      <AddMember
        onAdd={handleAddTeamMember}
        onUpdate={handleUpdateTeamMember}
        humanResources={tempHumanResources}
        editingMember={editingIndex !== null ? tempHumanResources[editingIndex] : null}
      />
      <TeamList
        teamMembers={tempHumanResources}
        onEdit={handleEditTeamMember}
        onDelete={handleDeleteTeamMember}
      />
      </div>
    </div></HRProvider>
  );
};

export default AddTeamMember;
