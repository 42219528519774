import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./DashboardLayout.module.css";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import logo from "../../assets/images/Estimaze-register-logo.png";
import CompleteProfileStatus from "../../components/auth/CompleteProfileStatus";
import Header from "../../components/Header";
import GlobalLoader from "../../components/common/GlobalLoader";
import useAuth from "../../hooks/useAuth";
import { HRProvider } from "../../contexts/HRContext";
const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { loading } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <HRProvider>
      <div className={`${styles[`dash-layout`]}`}>
        <div className={styles[`dash-sidebar`]}>
          <Sidebar isOpen={sidebarOpen}>
            <div className={`${styles.logo} width--full center--all`}>
              <img src={logo} alt="Logo" />
            </div>
            <Navbar toggleSidebar={toggleSidebar} />
            <CompleteProfileStatus />
          </Sidebar>
        </div>
        <div className={`${styles["dash-content"]} `}>
          <Header toggleSidebar={toggleSidebar} isOpen={sidebarOpen} />
          <Outlet />
        </div>
        {loading && <GlobalLoader />}
      </div>
    </HRProvider>
  );
};

export default DashboardLayout;
