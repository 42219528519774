import React, { useContext } from "react";
import DeleteIcon from "../common/DeleteIcon";
import EditIcon from "../common/EditIcon";
import styles from "./ProjectItem.module.css";
import { useNavigate } from "react-router-dom";
import GenerateProposalButton from "../buttons/GenerateProposalButton";
import { ProjectContext } from "../../contexts/ProjectContext";
const ProjectItem = ({ project }) => {
  const {handleDeleteProject, setEditProjectDetails} = useContext(ProjectContext)
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/project/?project-id=${project.id}`);
  };
  const handleEdit = () => {
    setEditProjectDetails(true)
    navigate(`/project/?project-id=${project.id}`);
  };

  const handleDelete = () => {
    handleDeleteProject(project.id)
  }
  return (
    <tr className={styles.project} >
      <td className={styles.title} onClick={(e)=>{
      e.preventDefault();
      handleClick();
    }} >{project.title}</td>
      <td className={styles.cost}>{project.cost ? project.cost : "-"}</td>
      <td className={styles[`time-line`]}>{project.timeline}</td>
      <td className={styles[`creation-date`]}>
        {project.modified ? (
          new Date(project.modified).toDateString()
        ) : (
          <GenerateProposalButton />
        )}
      </td>
      <td className={styles.actions}>
        <EditIcon action={handleEdit} />
        <DeleteIcon action={handleDelete} />
      </td>
    </tr>
  );
};

export default ProjectItem;
