import React, { createContext, useState, useEffect, useCallback } from "react";
import {
  getProfile,
  login,
  register,
  changePic,
  updateProfile,
  resetPasswordService,
  sendOtp as sendOtpService,
  verifyOtp as verifyOtpService,
  updateUserName as updateUserNameService,
  updatePortfolio as updatePortfolioService,
  updateResume as updateResumeService,
} from "../services/authService";
import { toast } from "react-toastify";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [resetToken, setResetToken] = useState(null);

  const fetchProfile = useCallback(async () => {
    setLoading(true);
    try {
      const profile = await getProfile();
      await new Promise((resolve) => setTimeout(resolve, 500));
      setUser(profile.data);
    } catch (err) {
      console.error("Failed to fetch profile:", err);
      setError("Failed to fetch profile. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (token) {
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, [token, fetchProfile]);

  const extractErrorMessage = (error) => {
    if (error.response && error.response.data) {
      const { message } = error.response.data;
      return message || "An unexpected error occurred.";
    }
    if (error.message) {
      return error.message;
    }
    return "An unexpected error occurred. Please try again.";
  };

  const saveToken = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  const deleteToken = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const loginUser = useCallback(async (username, password) => {
    setLoading(true);
    try {
      const user = await login(username, password);
      saveToken(user.data[`access_token`]);
      setError(null);
    } catch (err) {
      console.error("Failed to login:", err);
      toast.error(extractErrorMessage(err));
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, []);

  const resetPassword = async (newPassword) => {
    try {
      await resetPasswordService(resetToken, newPassword);
    } catch (error) {
      throw error;
    }
  };

  const verifyOtp = useCallback(async (email, otp_code) => {
    setLoading(true);
    try {
      const response = await verifyOtpService(email, otp_code);
      setError(null);
       
      setResetToken(response.data.reset_password_token);

      return response;
    } catch (err) {
      console.error("Failed to verify OTP:", err);
      toast.error(extractErrorMessage(err));
      setError(extractErrorMessage(err));
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const sendOtp = useCallback(async (email) => {
    setLoading(true);
    try {
      const response = await sendOtpService(email);
      setError(null);
      return response.message;
    } catch (err) {
      toast.error(extractErrorMessage(err));
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, []);

  const registerUser = useCallback(async (userData) => {
    setLoading(true);
    try {
      const user = await register(userData);
      saveToken(user.data[`access_token`]);
      setError(null);
      return user;
    } catch (err) {
      toast.error(extractErrorMessage(err));
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, []);

  const logoutUser = useCallback(() => {
    setUser(null);
    setError(null);
    deleteToken();
  }, []);

  const editProfile = useCallback(async (profileData) => {
    setLoading(true);
    try {
      const updatedUser = await updateProfile(profileData);
      if (updatedUser) {
        fetchProfile();
      }
      setError(null);
    } catch (err) {
      console.error("Failed to update profile:", err);
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [fetchProfile]);

  const getTeamMembers = () => {
    fetchProfile();
  };

  const changeProfilePic = async (profilePic) => {
    setLoading(true);
    try {
      const updatedProfilePic = await changePic(profilePic);
      setUser(updatedProfilePic.data);
      setError(null);
    } catch (err) {
      console.error("Failed to update profile picture:", err);
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const updateUserName = useCallback(async (full_name) => {
    setLoading(true);
    try {
      await updateUserNameService(full_name);
      await fetchProfile();
      setError(null);
    } catch (err) {
      console.error("Failed to update user name:", err);
      toast.error(extractErrorMessage(err));
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [fetchProfile]);

  const updatePortfolio = useCallback(async (portfolio) => {
    setLoading(true);
    try {
      await updatePortfolioService(portfolio);
      await fetchProfile();
      toast.info("Portfolio updated successfully");
      setError(null);
    } catch (err) {
      console.error("Failed to update portfolio:", err);
      toast.error(extractErrorMessage(err));
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [fetchProfile]);

  const updateResume = useCallback(async (resume) => {
    setLoading(true);
    try {
      await updateResumeService(resume);
      await fetchProfile();
      toast.info("Resume updated successfully");
      setError(null);
    } catch (err) {
      console.error("Failed to update resume:", err);
      toast.error(extractErrorMessage(err));
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [fetchProfile]);

  const contextValue = {
    user,
    resetToken,
    fetchProfile,
    loading,
    setLoading,
    error,
    resetPassword,
    loginUser,
    changeProfilePic,
    registerUser,
    logoutUser,
    token,
    deleteToken,
    getTeamMembers,
    saveToken,
    editProfile,
    updateUserName,
    updatePortfolio,
    sendOtp,
    updateResume,
    verifyOtp,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
