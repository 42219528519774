import React from "react";
import styles from "./AddButton.module.css";

const AddButton = ({ text, onClick, disabled }) => {
  return (
    <div
      type="submit"
      className={`${styles.AddButton} width--full`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </div>
  );
};

export default AddButton;
