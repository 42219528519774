import React from "react";
import styles from "./Header.module.css";
import LogOutButton from "../common/LogOutButton";
import AddButton from "../buttons/AddButton";
import { useNavigate } from "react-router-dom";
function Header({ toggleSidebar, isOpen }) {
  const navigate = useNavigate();

  
  const handleNavigateToDashboard = () => {
    navigate('/')
  }
  return (
    <div className={`${styles.container} grid--2`}>
      <div className={styles[`left-col`]}>
        <div
          className={`${styles[`wrapper-menu`]} ${isOpen ? styles.open : ""}`}
          onClick={(e) => {
            e.preventDefault();
            toggleSidebar();
          }}
        >
          <div
            className={`${styles[`line-menu`]} ${styles[`half`]} ${
              styles[`start`]
            }`}
          ></div>
          <div className={`${styles[`line-menu`]}`}></div>
          <div
            className={`${styles[`line-menu`]} ${styles[`half`]} ${styles[`end`]}`}
          ></div>
        </div>
        <AddButton text={'My Projects'} onClick={handleNavigateToDashboard} />
      </div>
      <div className={styles[`right-col`]}>
        <LogOutButton />
      </div>
    </div>
  );
}

export default Header;
