import React from "react";
import styles from "./Navbar.module.css";
import MenuItem from "./MenuItem";
import { DashboardIcon, HRIcon, ProfileIcon, PortfolioIcon } from "./MenuIcons";

function Navbar({ toggleSidebar }) {
  return (
    <div className={styles.navbar}>
      <MenuItem
        to="/dashboard"
        icon={<DashboardIcon />}
        title="Dashboard"
        // onClick={toggleSidebar}
      />
      <MenuItem
        to="/human-resource"
        icon={<HRIcon />}
        title="Human Resource"
        // onClick={toggleSidebar}
      />
      <MenuItem
      to="/portfolio"
      icon={<PortfolioIcon />}
      title="Portfolio"
      />
      <MenuItem
        to="/profile"
        icon={<ProfileIcon />}
        title="Profile"
        // onClick={toggleSidebar}
      />
    </div>
  );
}

export default Navbar;
