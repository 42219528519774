import React, { useContext, useEffect, useState } from "react";
import styles from "./HumanResource.module.css";
import AddMember from "../../components/AddMember";
import TeamList from "../../components/TeamList";
import { toast } from "react-toastify";
import { HRContext } from "../../contexts/HRContext";
import { AuthContext } from "../../contexts/AuthContext";

const HumanResource = () => {
  const [editingIndex, setEditingIndex] = useState(null);
  const { handleAddHumanResource } = useContext(HRContext);
  const { user, fetchProfile, setLoading} = useContext(AuthContext);
  const [tempHumanResources, setTempHumanResources] = useState(user?.human_resources || []);
  const notify = (message) => toast(message);

  useEffect(()=>{
    setTempHumanResources(user?.human_resources || []);
  },[user])
  const handleAddTeamMember = async (newMember) => {
    if (!Array.isArray(newMember.human_resources) || newMember.human_resources.length === 0) {
      notify("No team member data provided");
      return;
    }
    
    const lastMember = newMember.human_resources[newMember.human_resources.length - 1];
  
    if (!lastMember?.employee_full_name || !lastMember?.expertise || !lastMember?.exp_year) {
      notify("Complete the form to add a team member");
      return;
    }
  
    const human_resources = [...tempHumanResources, lastMember];
    setTempHumanResources(human_resources);
  
    try {
      setLoading(true);
      await handleAddHumanResource({ human_resources });
      await fetchProfile();
      notify("Team member added successfully");
    } catch (error) {
      notify("Failed to add team member");
    }
  };
  
  const handleEditTeamMember = (id) => {
 
    const memberIndex = tempHumanResources.findIndex((member) => member.id === id);
    
    if (memberIndex !== -1) {
      setEditingIndex(memberIndex);
    } else {
      notify("Team member not found");
    }
  };

  const handleUpdateTeamMember = async (updatedMember) => {
    const updatedResources = tempHumanResources.map((member, idx) =>
      idx === editingIndex ? updatedMember : member
    );
  
    setTempHumanResources(updatedResources);
  
    try {
      setLoading(true);
      await handleAddHumanResource({ human_resources: updatedResources });
      await fetchProfile();
      notify("Team member updated successfully");
      setEditingIndex(null);
    } catch (error) {
      notify("Failed to update team member");
    }
  };
  
  const handleDeleteTeamMember = async (id) => {
    // Find the index of the member with the matching id
    const updatedResources = tempHumanResources.filter((member) => member.id !== id);
  
    // Log the updated resources for debugging purposes
    console.log(updatedResources);
  
    // Update the state with the filtered array
    setTempHumanResources(updatedResources);
  
    try {
      setLoading(true);
      // Send the updated human resources list to the backend
      await handleAddHumanResource({ human_resources: updatedResources });
      await fetchProfile();
      notify("Team member deleted successfully");
      setEditingIndex(null);
    } catch (error) {
      notify("Failed to delete team member");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className={styles.container}>
      <div className={`${styles.name} text--base-medium`}>Team Members:</div>
      <AddMember
        onAdd={handleAddTeamMember}
        onUpdate={handleUpdateTeamMember}
        humanResources={tempHumanResources}
        editingMember={editingIndex !== null ? tempHumanResources[editingIndex] : null}
      />
      <TeamList
        teamMembers={tempHumanResources}
        onEdit={handleEditTeamMember}
        onDelete={handleDeleteTeamMember}
      />
    </div>
  );
};

export default HumanResource;
