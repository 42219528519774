// src/api/endpoints.js

export const AUTH_CHANGE_PIC = "/account/change-pic/";
export const AUTH_GMAIL_LOGIN = "/account/gmail-login/";
export const AUTH_HUMAN_RESOURCE = "/account/human-resource/";
export const AUTH_INFO = "/account/info/";
export const AUTH_LOGIN = "/account/login/";
export const AUTH_REGISTER = "/account/register/";
export const AUTH_OTP_VERIFICATION = "/account/otp-verification/";
export const AUTH_CHANGE_PASS = "/account/change-pass/";
export const AUTH_SEND_OTP = "/account/send-otp/"
export const AUTH_VERIFY_OTP = "/account/otp-verification/"
export const AUTH_RESET_PASS =  "/account/change-pass/"
// Additional endpoints for other services
export const AI_BOM = "/ai/bom/";
export const AI_PROPOSAL = "/ai/proposal/";
export const DASHBOARD_PROJECT_SEARCH = "/dashboard/project-search/";
export const DASHBOARD_PROJECT = "/dashboard/project/";
