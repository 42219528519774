import React, { useState } from 'react';
import styles from './Upload.module.css';
import Dropzone from '../Dropzone';
import Progress from '../Progress';
import img from '../../assets/icons/upload.jpg';
import { withPortfolio } from '../../contexts/PortfolioContext';

const Upload = ({ saveImage, closeModal }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [successfulUploaded, setSuccessfulUploaded] = useState(false);

  const onFilesAdded = (newFiles) => {
    setFiles(prevFiles => prevFiles.concat(newFiles));
  };

  const renderActions = () => {
    if (successfulUploaded) {
      return (
        <button
          onClick={() => {
            setFiles([]);
            setSuccessfulUploaded(false);
          }}
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={files.length === 0 || uploading}
          onClick={uploadFiles}
        >
          Upload
        </button>
      );
    }
  };

  const renderProgress = (file) => {
    const progress = uploadProgress[file.name];
    if (uploading || successfulUploaded) {
      return (
        <div className={styles.ProgressWrapper}>
          <Progress progress={progress ? progress.percentage : 0} />
          <img
            className={styles.CheckIcon}
            alt="done"
            src={img}
            style={{
              opacity: progress && progress.state === 'done' ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  };

  const uploadFiles = async () => {
    if (typeof saveImage !== 'function') {
      console.error('saveImage function is not defined');
      return;
    }

    setUploading(true);
    const promises = files.map(async (file) => {
      const formData = new FormData();
      formData.append('image_file', file);
      formData.append('type', 'img');
      formData.append('alt', 'image');
      formData.append('tag_type', 'img');
      formData.append('name', file.name);
      formData.append('order', 0);

      try {
        await saveImage(formData); 
        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [file.name]: { state: 'done', percentage: 100 },
        }));
      } catch (error) {
        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [file.name]: { state: 'error', percentage: 0 },
        }));
        console.error('Error uploading file:', error);
      }
    });

    try {
      await Promise.all(promises);
      setSuccessfulUploaded(true);
      setUploading(false);
    } catch (error) {
      console.error('Error during upload:', error);
      setSuccessfulUploaded(true);
      setUploading(false);
    } finally {
      closeModal()
      window.location.reload() 
    }
  };

  return (
    <div className={styles.Upload}>
      <span className={styles.Title}>Upload Files</span>
      <div className={styles.Content}>
        <div>
          <Dropzone
            onFilesAdded={onFilesAdded}
            disabled={uploading || successfulUploaded}
          />
        </div>
        <div className={styles.Files}>
          {files.map(file => (
            <div key={file.name} className={styles.Row}>
              <span className={styles.Filename}>{file.name}</span>
              {renderProgress(file)}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.Actions}>{renderActions()}</div>
    </div>
  );
};

export default withPortfolio(Upload);