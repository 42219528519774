import React, { useState, useContext } from "react";
import { ProjectContext } from "../../../contexts/ProjectContext";
import styles from "./UploadRFQFile.module.css";
import { toast } from "react-toastify";

const UploadRFQFile = () => {
  const [file, setFile] = useState(null);
  const { handleUploadRFQ, loading, loadProjects } = useContext(ProjectContext);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (file) {
      try {
        const res = await handleUploadRFQ(file);
        toast.error(res)
        loadProjects();
      } catch (error) {
        toast.error("Something went wrong. Try again");
      }
    } else {
      toast.error("Please select a file to upload.");
    }
  };

  return (
    <div className={styles.container}>
      <h4 className={styles[`title-bold`]}>
        Please upload the RFQ to generate a proposal and determine your
        eligibility for this project.
      </h4>
      <p className={styles[`title`]}>Allowed file formats: PDF, DOCX, TXT</p>
      <input
        type="file"
        accept=".pdf,.docx,.txt"
        onChange={handleFileChange}
        className={styles.fileInput}
        disabled={loading}
      />
      <button
        onClick={handleFileUpload}
        className={styles.uploadButton}
        disabled={loading}
      >
        {loading ? "Uploading..." : "Upload File"}
      </button>
    </div>
  );
};

export default UploadRFQFile;
