import React from "react";
import styles from "./UploadProfilePicture.module.css";
import UploadProfilePictureForm from "../../components/auth/UploadProfilePictureForm";
function UploadProfilePicture() {
  return (
    <div className={`${styles.authContainer} pad--xl`}>
      <h2>Please upload your profile picture</h2>
      <UploadProfilePictureForm />
    </div>
  );
}

export default UploadProfilePicture;
