import { toast } from "react-toastify";
import apiClient from "../api/apiClient";
import {
  DASHBOARD_PROJECT,
  DASHBOARD_PROJECT_SEARCH,
  AI_PROPOSAL,
} from "../api/endpoints";

export const fetchProjects = async () => {
  try {
    const response = await apiClient.get(DASHBOARD_PROJECT_SEARCH, {
      headers: { includeAuth: true },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch projects:", error);
    throw error;
  }
};

export const fetchProject = async (projectId) => {
  try {
    const response = await apiClient.get(
      `${DASHBOARD_PROJECT}?project_id=${projectId}`,
      {
        headers: { includeAuth: true },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch project:", error);
    throw error;
  }
};


export const uploadRFQ = async (file) => {
  try {
    const formData = new FormData();
    formData.append('project_file', file);

    const response = await apiClient.post('https://api.estimaze.org/api/v1/ai/bom/', formData,  {
      headers: {"Content-Type": "multipart/form-data", includeAuth: true },
    });

    if (response.status === 200) {
      toast.success('File uploaded successfully!');
    } else {
      toast.error('File upload failed. Please try again.');
    }
  } catch (error) {
    if (error.response) {
      toast.error(`Error: ${error.response.status} - ${error.response.data.message || 'An error occurred'}`);
    } else if (error.request) {
      toast.error('No response from server. Please check your network or try again later.');
    } else {
      toast.error(`Error: ${error.message}`);
    }
  }
};


export const generateProposalFn = async (projectId) => {
  try {
    const response = await apiClient.post(
      `${AI_PROPOSAL}?project_id=${projectId}`,
      {},
      {
        headers: { includeAuth: true },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to generate proposal:", error);
    throw error;
  }
};


export const deleteProject = async (projectId) => {
  try {
    const response = await apiClient.delete(
      `${DASHBOARD_PROJECT}?project_id=${projectId}`,
      {
        headers: { includeAuth: true },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete project:", error);
    throw error;
  }
};


export const editProject = async (projectId, updatedData) => {
  try {
    const response = await apiClient.put(
      `${DASHBOARD_PROJECT}?project_id=${projectId}`,
      updatedData,
      {
        headers: { includeAuth: true },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to edit project:", error);
    throw error;
  }
};
