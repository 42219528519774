import React, { useContext, useState, useEffect } from "react";
import styles from "./ProjectProposal.module.css";
import GenerateProposalButton from "../../buttons/GenerateProposalButton";
import { PacmanLoader, PuffLoader } from "react-spinners";
import { ProjectContext } from "../../../contexts/ProjectContext";
import EditIcon from "../../common/EditIcon";

const ProjectProposal = ({ proposal: initialProposal, id }) => {
  const { loading, setEditProjectDetails, editProjectDetails, handleEditProject } = useContext(ProjectContext);
  const [proposal, setProposal] = useState(initialProposal || "");

  const handleProposalChange = (e) => {
    setProposal(e.target.value);
  };

  useEffect(() => {
    setProposal(initialProposal);
  }, [initialProposal]);

  const handleSave = () => {
    handleEditProject(id, { proposal });
  };

  if (loading) { 
    return (
      <div className={styles.container}>
        <div>
          <h1>Proposal</h1>
        </div>
        <div className={styles.load}>
          <PacmanLoader color="#ff7f00" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <h1>Proposal</h1>
        <div className={styles.edit}>
          <EditIcon action={()=>{setEditProjectDetails(!editProjectDetails)}} />

          {editProjectDetails && <div className={styles.save} onClick={handleSave}>Save</div>}
        </div>
      </div>

      {editProjectDetails ? (
        <textarea
          className={styles.proposalInput}
          value={proposal}
          onChange={handleProposalChange}
          placeholder="Enter your proposal here..."
        />
      ) : (
        <p>{proposal}</p>
      )}

      {!proposal && !loading && <GenerateProposalButton id={id} />}
    </div>
  );
};

export default ProjectProposal;
