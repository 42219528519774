import React from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa'; 
import styles from './LivePreview.module.css';
import { usePortfolio } from '../../../contexts/PortfolioContext';

const LivePreview = () => {
  const { elements, selectedElementIndex, setSelectedElementIndex, removeElement, openModalForExistingElement, setUpdatingElement } = usePortfolio();

  const handleElementDelete = (index) => {
    removeElement(index);
  }

  const handleSelectElement = (index, order) => {
    console.log(index)
    setSelectedElementIndex(index);
    setUpdatingElement(index)

  };
  const sortedElements = elements ? [...elements].sort((a, b) => a.order - b.order) : [];

  if (sortedElements.length === 0) {
    return <div className={styles.livePreview}>No elements added yet</div>;
  }

  const renderElement = (element) => {
    const { tag_type, value, image_file, alt, width, height } = element;

    switch (tag_type) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6':
        return React.createElement(tag_type, null, value);

      case 'p':
        return <p>{value}</p>;

      case 'img':
        return (
          <img
            src={image_file || ''}
            alt={alt || 'Image'}
            width={width || 'auto'}
            height={height || 'auto'}
          />
        );

      default:
        return <p>Unsupported element type: {tag_type}</p>;
    }
  };

  return (
    <div className={styles.livePreview}>
      <div className={styles.container}>
        {sortedElements.map((element, index) => (
          <div
            key={element.id}
            onClick={() => handleSelectElement(index, element.order)}
            className={`${styles.elementWrapper} ${selectedElementIndex === index ? styles.selected : ''}`}
          >
            {renderElement(element)}
            {selectedElementIndex === index && element.tag_type !== "img" && (
             <>
              <div
              className={styles.editIconWrapper}
              onClick={() => openModalForExistingElement(index)} 
            >
               

              <FaPencilAlt className={styles.editIcon} /> 
            </div>
             </>
            )}
            {selectedElementIndex === index && (
             <>
            <div className={styles.deleteIconWrapper} onClick={() => handleElementDelete(index)} >
                <FaTrashAlt className={styles.deleteIcon} />
              </div>
             </>
            )}
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default LivePreview;