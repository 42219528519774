import React from "react";
import styles from "./LogOutButton.module.css";
import LogoutIcon from "./LogoutIcon";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
function LogOutButton() {
  const { deleteToken } = useAuth();
  const navigate = useNavigate();
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        deleteToken();
        navigate("/login");
      }}
      className={styles.logout}
    >
      <LogoutIcon />
      Log Out
    </div>
  );
}

export default LogOutButton;
