import React from "react";
import styles from "./Modal.module.css";
import { FaClosedCaptioning } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          {children}
          <div className={styles.modalActions}>
            <button
              className={`${styles.btn} ${styles.btnClose}`}
              onClick={onClose}
            >
              <FaX />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
