import apiClient from "../api/apiClient";

// Add a new portfolio element
export const addElement = async (element) => {
  try {
    const response = await apiClient.post("/account/portfolio/", element, {
      headers: { includeAuth: true },
    });
    return response.data; // Ensure this is returned
  } catch (error) {
    console.error("Failed to add element:", error);
    throw error; // Re-throw the error to handle it in .catch
  }
};

// Update an existing portfolio element
export const updateElement = async (element) => {
  try {
    const response = await apiClient.put(`/account/portfolio/?portfolio_element_id=${element.id}`, element, {
      headers: { includeAuth: true },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update element:", error);
    throw error;
  }
};

// Get all portfolio elements
export const getElement = async () => {
  try {
    const response = await apiClient.get("/account/portfolio/", {
      headers: { includeAuth: true },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to get elements:", error);
    throw error;
  }
};

// Delete a portfolio element by ID
export const deleteElement = async (elementId) => {
  try {
    const response = await apiClient.delete(`/account/portfolio/?portfolio_element_id=${elementId}`, {
      headers: { includeAuth: true },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to delete element:", error);
    throw error;
  }
};

// Reorder portfolio elements
export const reorderElement = async (elements) => {
  try {
    const response = await apiClient.put("/account/portfolio/reorder/", { elements }, {
      headers: { includeAuth: true },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to reorder elements:", error);
    throw error;
  }
};

// Add an image to the portfolio
export const addImage = async (formData) => {
  try {
    const response = await apiClient.post("/account/portfolio/", formData, {
      headers: { includeAuth: true,  "Content-Type": "multipart/form-data", },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to add image:", error);
    throw error;
  }
};