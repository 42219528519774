import React, { createContext, useState } from "react";
import { addHumanResource } from "../services/hrService";

export const HRContext = createContext();

export const HRProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const handleAddHumanResource = async (resource) => {
    setLoading(true);
    try {
      return await addHumanResource(resource);
    } catch (error) {
      console.error("Failed to handle human resource:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <HRContext.Provider
      value={{
        loading,
        handleAddHumanResource,
      }}
    >
      {children}
    </HRContext.Provider>
  );
};
