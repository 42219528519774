import React, { useContext, useState } from "react";
import styles from "./Profile.module.css";
import userImg from "../../assets/icons/user-placeholder.svg";
import { ReactComponent as PersonalIcon } from "../../assets/icons/user.svg";
import { ReactComponent as CompanyIcon } from "../../assets/icons/building.svg";
import AccountTypeRadio from "../../components/inputs/AccountTypeRadio";
import EmailInput from "../../components/inputs/EmailInput";
import NumberInput from "../../components/inputs/NumberInput";
import PasswordInput from "../../components/inputs/PasswordInput";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/AuthContext";
import CompanyAccountForm from "../../components/auth/CompanyAccountForm";
import PersonalAccountForm from "../../components/auth/PersonalAccountForm";
import SubmitButton from "../../components/buttons/SubmitButton";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";

function Profile() {
  const { editProfile, changeProfilePic, user, error: authError } = useContext(AuthContext);
  const [accountType, setAccountType] = useState(user?.user_info?.account_type || "personal");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [profilePic, setProfilePic] = useState(null);

  // Handle profile picture change
  const handleChangeProfilePic = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(file);
    }
  };

  // Handle form submission
  const onSubmit = async (data) => {
    const { confirmPassword, ...formData } = data;
    try {
      // Handle profile picture upload if a new picture is selected
      if (profilePic) {
        const formDataPic = new FormData();
        formDataPic.append("profile_pic_path", profilePic);
        await changeProfilePic(formDataPic);
      }
      
      // Update profile data
      await editProfile({ ...formData, accountType });
      toast.info('Profile updated successfully');
    } catch (error) {
      console.error("Profile update error:", error.message);
      toast.error("Failed to update profile");
    }
  };

  // Loading state
  if (!user) {
    return <div className={styles.load}><PuffLoader color="#ff7f00" /></div>;
  }

  return (
    <div className={`${styles.container} width--full`}>
      <div>
        <div className={`${styles.wrapper} grid--2 grid--m-1`}>
          <div className={styles.picRow}>  
            <div>
              <img
                src={profilePic ? URL.createObjectURL(profilePic) : user?.user_info?.profile_pic_path || userImg}
                alt="User"
                width={100}
              />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleChangeProfilePic}
              style={{ display: "none" }}
              id="profilePicUpload"
            />
            <label htmlFor="profilePicUpload" className={styles.uploadLabel}>
              Change Profile Picture
            </label>
          </div>
          <div className={styles.save}>
            <SubmitButton
              text="Save Changes"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>

        <div className={`${styles.name} text--base-medium`}>Account type:</div>
        <div className={styles.accountTypeWrapepr}>
          <div className={`${styles.formGroup} grid--2 center--all`}>
            <AccountTypeRadio
              id="personal"
              label="Personal Account"
              icon={<PersonalIcon />}
              checked={accountType === "personal"}
              onChange={() => setAccountType("personal")}
            />
            <AccountTypeRadio
              id="company"
              label="Company Account"
              icon={<CompanyIcon />}
              checked={accountType === "company"}
              onChange={() => setAccountType("company")}
            />
          </div>
        </div>

        <div className={`${styles.name} text--base-medium`}>Login Info:</div>
        <form className="register-form">
          <div className="grid--3 gap--xs grid--s-1 grid--m-2">
            <EmailInput
              label="Email Address"
              defaultValue={user?.user_info?.email}
              placeholder="Enter your email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Email is not valid",
                },
              })}
              error={errors.email?.message}
            />
            <NumberInput
              label="Phone"
              type="tel"
              defaultValue={user?.user_info?.phone}
              placeholder="Enter your phone number"
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]{10,15}$/,
                  message: "Phone number is not valid",
                },
                maxLength: {
                  value: 15,
                  message: "Ensure this field has no more than 15 characters",
                },
              })}
              error={errors.phone?.message}
            />
            <PasswordInput
              label="Password"
              placeholder="Enter your password"
              {...register("password", { required: "Password is required" })}
              error={errors.password?.message}
            />
          </div>

          {authError && <p className="error">{authError}</p>}

          <div className={`${styles.name} text--base-medium`}>
            {accountType === "company" ? "Company Info:" : "Personal Info:"}
          </div>

          {accountType === "company" ? (
            <CompanyAccountForm data={user} register={register} errors={errors} />
          ) : (
            <PersonalAccountForm
              register={register}
              errors={errors}
              data={user}
            />
          )}
        </form>
      </div>
    </div>
  );
}

export default Profile;
