import React from "react";
import styles from "./TeamMember.module.css";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";

const TeamMember = ({ member, onEdit, onDelete }) => {
  return (
    <tr className={styles.teamMember}>
      <td className={styles.name}>
        {member.employee_full_name}
      </td>
      <td className={styles.expertise}>
        {member.expertise}
      </td>
      <td className={styles.exp_year}>
        {member.exp_year} years
      </td>
      <td className={styles.actions}>
        <EditIcon action={onEdit} />
        <DeleteIcon action={onDelete} />
      </td>
    </tr>
  );
};

export default TeamMember;