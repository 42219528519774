import React from 'react'
import styles from './Portfolio.module.css'
import { PortfolioProvider } from '../../contexts/PortfolioContext'
import PortfolioBuilder from '../../components/portfolio/PortfolioBuilder'
import PortfolioSidebar from '../../components/portfolio/PortfolioSidebar'
import LivePreview from '../../components/portfolio/LivePreview'
function Portfolio() {
  return (
    <PortfolioProvider>
    <div >
      <div className={styles.row}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
        <PortfolioBuilder />
        </div>
        <div className={`${styles.builderContent} ${styles.container}`}>
        <LivePreview />
      </div>
      </div>
      <PortfolioSidebar />
      </div>
     
    </div>
   
    </PortfolioProvider>
  )
}

export default Portfolio