import React from "react";
import styles from "./BomDetail.module.css";

const BomDetail = ({ billOfMaterials = [], billOfHumans = [] }) => {
  return (
    <div className={styles.bomDetail}>
      {billOfMaterials.length > 0 ? (
        billOfMaterials.map((bill, index) => (
          <table key={index} className={styles.table}>
            <thead>
              <tr>
                <th>Category</th>
                <th>Item</th>
                <th>Quantity</th>
                <th>Unit Cost (Low)</th>
                <th>Unit Cost (High)</th>
                <th>Total Cost (Low)</th>
                <th>Total Cost (High)</th>
              </tr>
            </thead>
            <tbody>
              {bill.bill_items.map((item, itemIndex) => (
                <tr key={itemIndex}>
                  {itemIndex === 0 && (
                    <td rowSpan={bill.bill_items.length}>{bill.category}</td>
                  )}
                  <td>{item.title}</td>
                  <td>
                    {item.quantity} {item.unit_size}
                  </td>
                  <td>${item.unit_cost_low}</td>
                  <td>${item.unit_cost_high}</td>
                  <td>${item.unit_cost_low * item.quantity}</td>
                  <td>${item.unit_cost_high * item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))
      ) : (
        <p>No Bill of Materials available.</p>
      )}

      {billOfHumans.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Role</th>
              <th>Responsibilities</th>
              <th>No. of People</th>
              <th>Estimated Time (hrs)</th>
              <th>Hourly Wage</th>
              <th>Total Wage Cost</th>
            </tr>
          </thead>
          <tbody>
            {billOfHumans.map((hr, index) => (
              <tr key={index}>
                <td>{hr.role}</td>
                <td>{hr.resps}</td>
                <td>{hr.count}</td>
                <td>{hr.estimated_time}</td>
                <td>${hr.hourly_wage}</td>
                <td>${hr.count * hr.estimated_time * hr.hourly_wage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Human Resources available.</p>
      )}
    </div>
  );
};

export default BomDetail;
