import React, { useState, useEffect } from "react";
import SubmitButton from "../../buttons/SubmitButton";
import useAuth from "../../../hooks/useAuth";
import styles from "./CompleteInfoForm.module.css";
import userImg from "../../../assets/icons/user-placeholder.svg";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PuffLoader } from "react-spinners";

function UploadProfilePictureForm() {
  const navigate = useNavigate();
  const { changeProfilePic, user, fetchProfile, updateUserName, loading } = useAuth();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch
  } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const watchedName = watch("full_name");

  useEffect(() => {
    const debounceSave = setTimeout(async () => {
      if (watchedName) {
        try {
          await updateUserName(watchedName); 
          toast.info("Name updated successfully.");
        } catch (error) {
          console.error(error.message);
          toast.error("Failed to update name. Please try again.");
        }
      }
    }, 1500);

    return () => clearTimeout(debounceSave);
  }, [watchedName, updateUserName]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onSubmit = async (data) => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("profile_pic_path", selectedFile);

    try {
      await changeProfilePic(formData);
      await fetchProfile();
      toast.success("Profile picture updated successfully.");
      
      navigate('/dashboard');
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to update profile picture. Please try again.");
    }
  };
  if(loading) {
    return <div className={styles.load}><PuffLoader color="#ff7f00" /></div>;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="width--full center--all">
        <label htmlFor="profilePic">
          <img
            src={
              user?.user_info?.profile_pic_path
                ? user?.user_info?.profile_pic_path
                : selectedFile
                ? URL.createObjectURL(selectedFile)
                : userImg
            }
            alt="User"
            width={100}
            style={{ cursor: "pointer" }}
          />
        </label>
        <input
          id="profilePic"
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept="image/*"
        />
        <input
          className={`${styles.name} text--base-medium`}
          placeholder="Full Name/Company Name"
          {...register("full_name")}
        />
      </div>
      {errors.profilePic && (
        <div className="error">{errors.profilePic.message}</div>
      )}
      <SubmitButton onClick={handleSubmit(onSubmit)} text="Next" disabled={isSubmitting} />
      <Link to="/dashboard" className={`${styles.skip} center--all pad--s`}>
        Skip Now
      </Link>
    </form>
  );
}

export default UploadProfilePictureForm;