import React, { useContext, useEffect } from "react";
import styles from "./TeamList.module.css";
import TeamMember from "../TeamMember";
import { AuthContext } from "../../contexts/AuthContext";
import { PuffLoader } from "react-spinners";

const TeamList = ({ teamMembers, onEdit, onDelete, isLoading }) => {
  const { getTeamMembers } = useContext(AuthContext);

  useEffect(() => {
    if (isLoading) {
      getTeamMembers();
    }
  }, [isLoading]);

  if (isLoading) {
    return <div className={styles.load}><PuffLoader color="#ff7f00" /></div>;
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Expertise</th>
            <th>Experience (Years)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {teamMembers?.length === 0 ? (
            <tr>
              <td className={styles.empty} colSpan="4">
                No Team Members Added
              </td>
            </tr>
          ) : (
            teamMembers.map((member, index) => (
              <TeamMember
                key={member.id} 
                member={member}
                onEdit={() => onEdit(member.id)}
                onDelete={() => onDelete(member.id)}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TeamList;