import React, { useContext } from "react";
import styles from "./GenerateProposalButton.module.css";
import { ProjectContext } from "../../../contexts/ProjectContext";

const GenerateProposalButton = ({id}) => {
  const {generateProposal} = useContext(ProjectContext)


  return (
    <button className={styles.button} onClick={(e)=>{
      e.preventDefault();
       generateProposal(id);
    }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        className={styles.icon}
      >
        <path
          d="M20 3.50001V7.50001M22 5.50001H18M4 17.5V19.5M5 18.5H3M9.937 16C9.84772 15.6539 9.66734 15.3381 9.41462 15.0854C9.1619 14.8327 8.84607 14.6523 8.5 14.563L2.365 12.981C2.26033 12.9513 2.16821 12.8883 2.10261 12.8014C2.03702 12.7146 2.00153 12.6088 2.00153 12.5C2.00153 12.3912 2.03702 12.2854 2.10261 12.1986C2.16821 12.1118 2.26033 12.0487 2.365 12.019L8.5 10.436C8.84595 10.3468 9.16169 10.1666 9.4144 9.91404C9.66711 9.66151 9.84757 9.34589 9.937 9.00001L11.519 2.86501C11.5484 2.75992 11.6114 2.66735 11.6983 2.6014C11.7853 2.53545 11.8914 2.49976 12.0005 2.49976C12.1096 2.49976 12.2157 2.53545 12.3027 2.6014C12.3896 2.66735 12.4526 2.75992 12.482 2.86501L14.063 9.00001C14.1523 9.34608 14.3327 9.6619 14.5854 9.91462C14.8381 10.1673 15.1539 10.3477 15.5 10.437L21.635 12.018C21.7405 12.0471 21.8335 12.11 21.8998 12.1971C21.9661 12.2841 22.0021 12.3906 22.0021 12.5C22.0021 12.6094 21.9661 12.7159 21.8998 12.8029C21.8335 12.89 21.7405 12.9529 21.635 12.982L15.5 14.563C15.1539 14.6523 14.8381 14.8327 14.5854 15.0854C14.3327 15.3381 14.1523 15.6539 14.063 16L12.481 22.135C12.4516 22.2401 12.3886 22.3327 12.3017 22.3986C12.2147 22.4646 12.1086 22.5003 11.9995 22.5003C11.8904 22.5003 11.7843 22.4646 11.6973 22.3986C11.6104 22.3327 11.5474 22.2401 11.518 22.135L9.937 16Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Generate Proposal
    </button>
  );
};

export default GenerateProposalButton;
