import React from "react";
import PropTypes from "prop-types";
import styles from "./SelectInput.module.css";

const SelectInput = ({ label, name, register, error, options, onChange, ...rest }) => (
  <div className={styles.inputWrapper}>
    {label && (
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
    )}
    <select
      id={name}
      className={`${styles.select} ${error ? styles.selectError : ""}`}
      {...register(name)}
      onChange={onChange}
      {...rest}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && <span className={styles.errorMessage}>{error.message}</span>}
  </div>
);

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  error: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SelectInput;
