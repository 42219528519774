import React, { createContext, useState, useEffect } from "react";
import { lightTheme, darkTheme } from "../styles/themes";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme === "dark") {
      setTheme(darkTheme);
      document.documentElement.classList.add("dark-mode");
    } else {
      setTheme(lightTheme);
      document.documentElement.classList.remove("dark-mode");
    }
  }, []);

  const toggleDarkMode = () => {
    if (theme === darkTheme) {
      setTheme(lightTheme);
      document.documentElement.classList.remove("dark-mode");
      localStorage.setItem("theme", "light");
    } else {
      setTheme(darkTheme);
      document.documentElement.classList.add("dark-mode");
      localStorage.setItem("theme", "dark");
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
