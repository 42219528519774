import React from 'react';
import { usePortfolio } from '../../../contexts/PortfolioContext';
import Modal from '../../common/Modal';
import styles from './PortfolioBuilder.module.css';
import ElementProperties from '../ElementProperties';

const PortfolioBuilder = () => {
  const {
    selectedElementIndex,
    isModalOpen,
    addElement,
    closeModal,
  } = usePortfolio();

  const handleAddElement = (element) => {
    addElement(element); 
  };

 

  return (
    <div className={styles.portfolioBuilder}>
      <h3>Builder Tools</h3>
      <div className={styles.buttonGroup}>
        <button onClick={() => handleAddElement({ type: 'text', tag_type: 'p', value: 'Text Element' })}>
          Add Text
        </button>
        <button onClick={() => handleAddElement({ type: 'heading', tag_type: 'h1', value: 'Heading Element' })}>
          Add Heading
        </button>
        <button onClick={() => handleAddElement({ type: 'image', tag_type: 'img', src: '', alt: 'portfolio image', width: 100, height: 100 })}>
          Add Image
        </button>
      </div>
      <Modal show={isModalOpen} onClose={closeModal}>
        {selectedElementIndex !== null && <ElementProperties   />}
      </Modal>
    </div>
  );
};

export default PortfolioBuilder;