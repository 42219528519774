import React from "react";
import styles from "./ScoreCard.module.css";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

// Registering the chart.js components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const ScoreCard = ({ score = 0, data  }) => {
  // Destructuring the data object
  const {
    exp_score = 0,
    resume_score = 0,
    portfolio_score = 0,
    human_resource_score= 0,
    location_score = 0,
  } = data;

  // Preparing radar chart data
  const radarData = {
    labels: [
      "Field of Experience",
      "Resume",
      "Portfolio",
      "Human Resource",
      "Location",
    ],
    datasets: [
      {
        label: "Score",
        data: [exp_score, resume_score, portfolio_score, human_resource_score, location_score],
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className={styles.container}>
      <div className={styles.scoreContainer}>
        <div className={styles.circle}>
          <div className={styles.score}>{score}/100</div>
        </div>
      </div>
      <div className={styles.chartContainer}>
        <Radar data={radarData} />
      </div>
    </div>
  );
};

export default ScoreCard;
