import React from "react";
import styles from "./Register.module.css";
import logo from "../../assets/images/Estimaze-register-logo.png";
import { Link } from "react-router-dom";
import RegisterForm from "../../components/auth/RegisterForm";
function index() {
  return (
    <div className={styles.authContainer}>
      <div className={styles.authLeft}>
        <img src={logo} alt="Estimaze" className={styles.logo} />
      </div>
      <div className={styles.authRight}>
        <h2>Create Your Estimaze Account</h2>
        <RegisterForm />
        <div className={styles.authFooter}>
          <p>
            Do you have an account? <Link to="/login">Login</Link>
          </p>
          {/* <p>Or Register With</p>
          <div className={styles.socialButtons}>
            <button className={styles.googleButton}>Google</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default index;
