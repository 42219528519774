import React, { useCallback } from 'react';
import { usePortfolio } from '../../../contexts/PortfolioContext';
import styles from './PortfolioSidebar.module.css';
import PortfolioElement from '../PortfolioElement';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const PortfolioSidebar = () => {
  const { elements, moveElement, openModal } = usePortfolio();

  // Memoized callback for handling element clicks
  const handleElementClick = useCallback((index) => {
    openModal(index);
  }, [openModal]);

 
  const sortedElements = elements ? [...elements].sort((a, b) => a.order - b.order) : [];

  const renderElements = () => {
    return sortedElements.length > 0 ? (
      sortedElements.map((element, index) => (
        <PortfolioElement
          key={element.id}  
          index={index}
          element={element}
          moveElement={moveElement}
          onClick={() => handleElementClick(index)}
        />
      ))
    ) : (
      <p>No elements available</p>
    );
  };

  return (
    <div className={styles.sidebar}>
      <h4 className={styles.title}>Drag to Reorder</h4>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.elementsList}>
          {renderElements()}
        </div>
      </DndProvider>
    </div>
  );
};

export default PortfolioSidebar;