import React from "react";
import styles from "./Login.module.css";
import logo from "../../assets/images/Estimaze-login-logo.png";
import { Link } from "react-router-dom";
import LoginForm from "../../components/auth/LoginForm";
const Login = () => {
  return (
    <div className={styles.authContainer}>
      <div className={styles.authLeft}>
        <img src={logo} alt="Estimaze" className={styles.logo} />
      </div>
      <div className={styles.authRight}>
        <h2>Login</h2>
        <LoginForm />
        <div className={styles.authFooter}>
          <p>
            Don’t have an account? <Link to="/register">Register</Link>
          </p>
          {/* <p>Or Log In With</p>
          <div className={styles.socialButtons}>
            <button className={styles.googleButton}>Google</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
