import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./AuthLayout.module.css";
import AuthBackground from "../../components/auth/AuthBackground";
import { HRProvider } from "../../contexts/HRContext";

const AuthLayout = () => {
  const location = useLocation();

  return (
    <div className={styles["auth-layout"]}>
      <HRProvider>
      <AuthBackground route={location.pathname} />
      <div className={styles["auth-content"]}>
        <Outlet />
      </div>
      </HRProvider>
    </div>
  );
};

export default AuthLayout;
