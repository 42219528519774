import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ITEM_TYPE } from '../../../utils/constants';
import styles from './PortfolioElement.module.css';
import { usePortfolio } from '../../../contexts/PortfolioContext';
import { FaPencilAlt, FaTrashAlt, FaGripVertical } from 'react-icons/fa';
import { gsap } from '../../../plugins/gsap.min.js';

const PortfolioElement = ({ element, index, moveElement, onClick }) => {
  const { selectedElementIndex, removeElement, setSelectedElementIndex  } = usePortfolio();
  const elementRef = useRef(null);

  const [, ref] = useDrag({
    type: ITEM_TYPE.ELEMENT,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ITEM_TYPE.ELEMENT,
    hover: (item) => {
      if (item.index !== index) {
        moveElement(item.index, index);
        item.index = index; // Update the index for the dragged item
      }
    },
  });

  useEffect(() => {
    if (selectedElementIndex === index) {
      gsap.to(elementRef.current, {
        duration: 0.5,
        ease: "power1.inOut",
        background: 'linear-gradient(90deg, rgba(255, 127, 0, 0.75) 0%, rgba(255, 255, 255, 1) 85%)',
        backgroundPosition: '100% 0', // Animate background position
        borderColor: 'var(--color-primary)',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Add a shadow effect
        scale: 1, // Slightly scale up the element
      });
    } else {
      gsap.to(elementRef.current, {
        duration: 0.5,
        ease: "power1.inOut",
        background: 'linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(255, 255, 255, 1) 19%)',
        backgroundPosition: '0% 0', // Reset background position
        borderColor: '#ccc', // Reset border color
        boxShadow: 'none', // Remove the shadow effect
        scale: 1, // Reset scale
      });
    }
  }, [selectedElementIndex, index]);

  const handleClick = () => {
    setSelectedElementIndex(index);
  };

  const handleDelete = (e) => {
    e.stopPropagation(); 
    removeElement(index);
  };

  return (
    <div
      ref={(node) => {
        ref(drop(node));
        elementRef.current = node;
      }}
      className={`${styles.portfolioElement} ${selectedElementIndex === index ? styles.selected : ''}`}
      onClick={handleClick}
    >
      <FaGripVertical className={styles.drag} />
      {element.tag_type === 'p' && <p>{element.value}</p>}
      {['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(element.tag_type) && React.createElement(element.tag_type, null, element.value)}
      {element.tag_type === 'img' && <img src={element.image_file} height='35' alt={element.alt} width={element.width}  />}
      <div className={styles.IconsWrapper}>
        {element.tag_type === 'img' && <FaPencilAlt className={styles.disabledEditIcon}  />}
        {element.tag_type !== 'img' && <FaPencilAlt className={styles.editIcon} onClick={onClick} />}
        <FaTrashAlt className={styles.deleteIcon} onClick={handleDelete} />
      </div>
    </div>
  );
};

export default PortfolioElement;