import React from "react";
import styles from './FileInput.module.css';

const FileInput = ({ label, accept, ...props }) => (
  <div className={styles['form-group']}>
    <label>{label}</label>
    <input type="file" accept={accept} {...props} />
  </div>
);

export default FileInput;