import React from "react";
import PropTypes from "prop-types";
import styles from "./DateInput.module.css";

const DateInput = React.forwardRef(
  ({ label, error, ...rest }, ref) => (
    <div className={styles.inputWrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <input
        type="date"
        ref={ref}
        className={`${styles.input} ${error ? styles.inputError : ""}`}
        {...rest}
      />
      {error && <span className={styles.errorMessage}>{error.message}</span>}
    </div>
  )
);

DateInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.object,
};

export default DateInput;
