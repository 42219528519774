import React, { useCallback, useEffect } from "react";
import EmailInput from "../../inputs/EmailInput";
import PasswordInput from "../../inputs/PasswordInput";
import SubmitButton from "../../buttons/SubmitButton";
import useAuth from "../../../hooks/useAuth";
import styles from "./LoginForm.module.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function LoginForm() {
  const { loginUser, saveToken , loading} = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  if (token) {
    saveToken(token);
  }

  const onSubmit = useCallback(async (data) => {
    try {
      const user = await loginUser(data.username, data.password);
      saveToken(user.data[`access_token`]);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed:", error.message);
    }
  }, [loginUser, saveToken, navigate]);

  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        handleSubmit(onSubmit)();
      }
    };

    window.addEventListener("keydown", handleEnterKey);

    return () => {
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [handleSubmit, onSubmit]);

  return (
    <form >
      <EmailInput
        label={"Email Address"}
        placeholder={"Enter your email"}
        {...register("username", { required: "Email is required" })}
        error={errors.username?.message}
      />
      <PasswordInput
        label={"Password"}
        placeholder={"Enter your password"}
        {...register("password", { required: "Password is required" })}
        error={errors.password?.message}
      />
      <div className={`${styles.formOptions} text--s grid--2`}>
        <label>
          <input type="checkbox" /> Remember Me
        </label>
        <Link to="/forgot-password" className={styles.forgotPassword}>
          Forgot Password?
        </Link>
      </div>
      <div className={styles.submit}>
      <SubmitButton
        text={loading ? `Connecting...` : `Login`}
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
      />
      </div>
    </form>
  );
}

export default LoginForm;
