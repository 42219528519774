// components/inputs/AccountTypeRadio.js
import React from "react";
import PropTypes from "prop-types";
import styles from "./AccountTypeRadio.module.css";

const AccountTypeRadio = ({ id, label, icon, checked, onChange }) => (
  <label className={`${styles.radioLabel} ${checked ? styles.checked : ""}`}>
    <input
      type="radio"
      id={id}
      name="accountType"
      value={id}
      checked={checked}
      onChange={onChange}
      className={styles.radioInput}
    />
    <div className={styles.wrapper}>
      <span className={styles.icon}>{icon}</span>
      <span className={styles.labelText}>{label}</span>
    </div>
  </label>
);

AccountTypeRadio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AccountTypeRadio;
