const typography = {
  fontFamily: "'Roboto', sans-serif",
  fontSize: "16px",
  headings: {
    h1: "2em",
    h2: "1.5em",
    h3: "1.17em",
  },
};

export default typography;
