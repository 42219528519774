import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import styles from "./ProjectLayout.module.css";
import ProjectSidebar from "../../components/project/ProjectSidebar";
import { ProjectContext } from "../../contexts/ProjectContext";
const ProjectLayout = () => {
  const { toggleSidebar, sidebarOpen } = useContext(ProjectContext);
  return (
    <div className={`${styles[`project-layout`]}`}>
      <div className={`${styles["project-content"]} `}>
        <Outlet />
      </div>
      <div className={styles[`project-sidebar`]}>
        <ProjectSidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      </div>
    </div>
  );
};

export default ProjectLayout;
